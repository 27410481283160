export const DGAuthMethod = {
  Unknown: "Unknown",
  Passwordless: "Passwordless",
  Google: "Google",
};

export const DGAuthState = {
  Unknown: "Unknown", // Initial state on page load
  Unauthenticated: "Unauthenticated",
  UnauthenticatedMagicLinkError: "UnauthenticatedMagicLinkError",
  SentMagicLink: "SentMagicLink",
  ResentMagicLink: "ResentMagicLink",
  SignedInPasswordless: "SignedInPasswordless",
  SignedInSocialGoogle: "SignedInSocialGoogle",
};
