import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { proxyclaim } from "../graphql/mutations";
import { ProxyclaimMutation } from "../API";

export const callProxyClaim = async (data: any, bearerToken: string, success: (data: any) => any, err: (code: number, msg: string) => any) => {
  try {
    const apiRequest = await API.graphql({
      query: proxyclaim,
      variables: { input: JSON.stringify(data) },
      authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
      authToken: bearerToken,
    });
    const apiResponse = apiRequest as { data: ProxyclaimMutation; errors: any[] };
    //console.log("[callProxyClaim] apiRequest: ", apiRequest);
    const response = JSON.parse(apiResponse.data.proxyclaim || "{}");
    
    /*
    Response body documentation
    
    */
    if (response.statusCode === 200) {
      const response_body = response.body;
      if (response_body) {
        success(response_body);
      } else {
        err(400, response_body);
      }
    } else {
      err(response.statusCode, `[callProxyClaim] Error in claim NFT: ${response.statusCode}: ${response.body}`);
    }
  } catch (error) {
    //console.log("[callProxyClaim] Error in API call: ", error);
    if (err) {
      err(400, `${error}`);
    }
  }
};
