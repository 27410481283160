import React from "react";
import { Grid, Box, Typography } from "@mui/material";
//import { MainContext } from "../../contexts/MainContext";
//import { useTranslation, Trans } from "react-i18next";

export function Footer() {
  //const mainContext = React.useContext(MainContext);
  //const { i18n } = useTranslation();

  // const changeLanguageTo = (lng: string) => {
  //   //console.log("changeLanguageTo: ", lng);
  //   mainContext?.setLanguage(lng);
  //   mainContext?.log_event_analytics("change_language", { language_to: lng });    
  //   i18n.changeLanguage(lng);
  // };

  // React.useState(() => {
  //   // Set detected language from i18n to mainContext language
  //   // Extract first two characters from detected language
  //   //const detectedLanguage = (i18n.language) ? i18n.language.substring(0, 2) : "en";
  //   //console.log("initially detected language: ", detectedLanguage);
  //   //mainContext.setLanguage(detectedLanguage);
  // }, []);

  const footerStyle = {
    background: "#413C54",
    width: "100%",
    height: "46px",
  };

  return (
    <Box sx={footerStyle} display={"flex"} alignItems="center" justifyContent="flex-start">        
      <Typography variant="dg_footer" ml={2}>© 2023 Hong Kong Monetary Authority. All rights reserved.</Typography>
    </Box>
  );
}

