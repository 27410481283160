import React from "react";
import { Box } from "@mui/material";

/*
Media returned from Alchemy:
{'gateway': 'https://nft-cdn.alchemy.com/matic-mainnet/2806b3cf90d6c7dd429ca7f4c04316b7',
 'thumbnail': 'https://res.cloudinary.com/alchemyapi/image/upload/thumbnailv2/matic-mainnet/2806b3cf90d6c7dd429ca7f4c04316b7',
 'raw': 'https://dot.dttd.group/SUZUKACIRCUIT/1.png',
 'format': 'png',
 'bytes': 696100}
 */
export type NFTMedia = {
  gateway: string;
  thumbnail: string;
  raw: string;
  format: string;
  bytes: number;
}

interface NFTViewerProps {
  title: string | undefined;
  media: NFTMedia | undefined;
  sx?: any | undefined
}

// eslint-disable-next-line react/prop-types
const NFTViewer:React.FC<NFTViewerProps> = ({title, media, sx}) => {
  const [showVideo, setShowVideo] = React.useState<boolean>(false);
  const [showImage, setShowImage] = React.useState<boolean>(false);
  const [displayVideoUrl, setDisplayVideoUrl] = React.useState<string>("");
  const [displayUrl, setDisplayUrl] = React.useState<string>("");
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const defaultBoxStyle = {
    maxWidth: "100%",
    minWidth: "343px",
    minHeight: "343px",
    margin: "0 auto"
  }

  const attemptPlay = () => {
    videoRef &&
      videoRef.current &&
      videoRef.current.play().catch((error: any) => {
        console.error("Error attempting to play", error);
      });
  };

  React.useEffect(() => {
    if (showVideo) {
      attemptPlay();
    }
  }, [showVideo]);

  React.useEffect(() => {
    if (media !== undefined) {
      // Check for media type to see any video related format
      if ((media.format === "mp4") || (media.raw.includes(".mp4"))) {
        const videoUrl = (media.raw.includes("https://dot.dttd.group")) ? media.raw : (media.gateway || media.raw);
        setDisplayVideoUrl(videoUrl);
        setShowVideo(true);
      } else {
        setShowImage(true);
        const imgUrl = (media.raw.includes("https://dot.dttd.group")) ? media.raw : (media.thumbnail || media.raw);
        setDisplayUrl(imgUrl);
      }
    }
  }, [media]);
  return (
    <Box sx={ (sx !== undefined) ? { ...defaultBoxStyle, ...sx } : defaultBoxStyle}>
      {showVideo && (
        <video
          ref={videoRef}
          src={displayVideoUrl}
          width="100%"
          height="100%"
          playsInline
          autoPlay
          muted
          loop
        />
      )}
      {showImage && (
        <img
          src={displayUrl}
          alt={title}
          style={{
            maxWidth: "100%",
            minWidth: defaultBoxStyle.minWidth || "343px",
            minHeight: defaultBoxStyle.minHeight || "343px",
            margin: "0 auto",
          }}
        />
      )}
    </Box>
  );
};

export { NFTViewer };