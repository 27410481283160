import React from 'react';
import { usePasswordless } from './passwordless/react';
import { Auth, Hub, API } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { DGAuthState, DGAuthMethod } from './model';
import { getLogger } from "../LogConfig";

interface DGAuthContextType {
  isAuthenticated: boolean;
  checkAuthenticated: () => Promise<boolean>;
  dgSignInEmail: (entityId: string, email: string, language: string, redirectUrl: string) => Promise<any> | false;
  dgSignInGoogle: () => void;
  dgSignOut: () => Promise<void>;
  getBearerToken: () => Promise<string>;
  authState: string;
  signedInEmail: string;
  signedInMethod: string;
  nextMagicLinkAllowedAt: number;
  resetMagicLinkFlow: () => void;
  signOutAt: number;
  preSignOutAt: number;
};

const DGAuthContext = React.createContext<DGAuthContextType | null>(null);

export function useDGPasswordless() {
  const context = React.useContext(DGAuthContext);
  if (!context) {
    throw new Error(
      "The DGAuthContextProvider must be added above this consumer in the React component tree"
    );
  }
  return context;
}

function _useDGPasswordless() {
  const logger = getLogger("service:useDGPasswordless");
  const [ isAuthenticated, setIsAuthenticated ] = React.useState<boolean>(false);
  const [ authMethod, setAuthMethod ] = React.useState<string>(DGAuthMethod.Passwordless);
  const [ signedInMethod, setSignedInMethod ] = React.useState<string>('');
  const [ authState, setAuthState ] = React.useState<string>(DGAuthState.Unknown);
  const [ session, setSession ] = React.useState<any>(null);
  const [ signedInEmail, setSignedInEmail ] = React.useState<string>('');
  const { tokens, tokensParsed, signOut, signInStatus, refreshTokens, lastError, signingInStatus } = usePasswordless();
  const [ lastSubmittedEmail, setLastSubmittedEmail] = React.useState<string>('');
  const [ lastMagicLinkSentAt, setLastMagicLinkSentAt] = React.useState<number>(0);
  const [ nextMagicLinkAllowedAt, setNextMagicLinkAllowedAt ] = React.useState<number>(0);
  const [ signOutAt, setSignOutAt ] = React.useState<number>(0);
  const [ preSignOutAt, setPreSignOutAt ] = React.useState<number>(0);

  React.useEffect(() => {
    if (tokensParsed) {
      setIsAuthenticated(true);
      setAuthState(DGAuthState.SignedInPasswordless);
      setSignedInMethod("Email Magic Link");
      setSignedInEmail(tokensParsed.idToken?.email || '');
    }
  }, [tokensParsed]);

  React.useEffect(() => {
    if (signInStatus === "SIGNED_IN") {
      setIsAuthenticated(true);
      setAuthState(DGAuthState.SignedInPasswordless);
    }
  }, [signInStatus]);

  React.useEffect(() => {
    logger.debug(`[useDGPasswordless] lastError: ${lastError}`);
  }, [lastError]);

  React.useEffect(() => {
    logger.debug(`[useDGPasswordless] signingInStatus: ${signingInStatus} DG-authState: ${authState}`);
    if (signingInStatus === "SIGNIN_LINK_EXPIRED") {
      // Invalid Magic Link -> Logout any session
      //dgSignOut();
      setAuthState(DGAuthState.UnauthenticatedMagicLinkError);
    } else if (signingInStatus === "SIGNED_IN_WITH_LINK") {
      setAuthState(DGAuthState.SignedInPasswordless);
    }
  }, [signingInStatus]);
  

  React.useEffect(() => {
    Auth.currentSession().then(session => {
      setIsAuthenticated(true);
      setAuthMethod(DGAuthMethod.Google);
      setAuthState(DGAuthState.SignedInSocialGoogle);
      setSession(session);
    }).catch(err => {
      //console.log('useDGPasswordless: useEffect at init: err=', err);
    });
    Auth.currentUserInfo().then(userData => {
      if (userData) {
        logger.debug(`[Init] Signed in user: ${JSON.stringify(userData)}`);
        const identities = userData.attributes.identities;
        const identitiesData = identities ? JSON.parse(identities) : null;
        if ((identitiesData) && (identitiesData.length > 0)) {
          setSignedInMethod(identitiesData[0].providerName);
          setSignedInEmail(userData.attributes.email);
          logger.debug(`[Sign in / cognitoHostedUI] set sign in method as: ${identitiesData[0].providerName} for email: ${userData.attributes.email}`);
        } else {
          setSignedInEmail(userData.attributes.email);
        }
        setSignedInEmail(userData.attributes.email);
      }
    }).catch(err => {
      //console.log('[ERROR] useDGPasswordless in getting user: ', err);
    });
    const listener = Hub.listen('auth', ({payload: {event, data}}) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          //logger.debug(`[Sign in / cognitoHostedUI] Sign in / cognitoHostedUI: ${JSON.stringify(data)}`);
          setIsAuthenticated(true);
          setAuthMethod(DGAuthMethod.Google);
          setAuthState(DGAuthState.SignedInSocialGoogle);
          Auth.currentSession().then(session => {
            setSession(session);
          }).catch(err => {
            //console.log('useDGPasswordless: useEffect at signIn: err=', err);
          });
          Auth.currentUserInfo().then(userData => {
            logger.debug(`[Sign in / cognitoHostedUI] Signed in user: ${JSON.stringify(userData)}`);
            const identities = userData.attributes.identities;
            const identitiesData = identities ? JSON.parse(identities) : null;
            if ((identitiesData) && (identitiesData.length > 0)) {
              setSignedInMethod(identitiesData[0].providerName);
              setSignedInEmail(userData.attributes.email);
              logger.debug(`[Sign in / cognitoHostedUI] set sign in method as: ${identitiesData[0].providerName} for email: ${userData.attributes.email}`);
            } else {
              setSignedInEmail(userData.attributes.email);
            }
          }).catch(err => {
            //console.log('[ERROR] useDGPasswordless in getting user: ', err);
          });
          break;
        case 'signOut':
          setSession(null);
          //console.log('Sign out', data);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          logger.info(`Sign in failure with data: ${data}`);
          break;
        // else
        default:
          //console.log('Auth event', event);
          break;
      }
    });

    return () => {
      // Remove Hub listener for disposal
      listener();
    };
  }, []);

  const checkAuthenticated = React.useCallback(async () => {
    try {
      if ((authState === DGAuthState.SignedInPasswordless) || (authState === DGAuthState.SignedInSocialGoogle)) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      //console.log('[ERROR] useDGPasswordless in checkAuthenticated: ', err);
      return isAuthenticated;
    }
  }, [authState]);
  

  const dgSignInEmail = (entityId: string, email: string, language: string, redirectUrl: string) => {
    // Check State
    if ((authState === DGAuthState.SentMagicLink) || (authState === DGAuthState.ResentMagicLink)) {
      // Check if last magic link sent is within 1 minute
      const intervalAllowed = (authState === DGAuthState.ResentMagicLink) ? 120000 : 60000; // Longer interval for ResentMagicLink
      if (lastMagicLinkSentAt && Date.now() - lastMagicLinkSentAt < intervalAllowed) {
        logger.info("[dgSignInEmail] Magic Link already sent within 1 minute");
        return false;
      }
    }

    const emailParam = email.toLowerCase();
    setLastSubmittedEmail(emailParam);

    // Call API to trigger email Magic Link request
    setAuthMethod(DGAuthMethod.Passwordless);
    setSignedInMethod("Email Magic Link");

    // Update state and sent timestamp
    if (authState === DGAuthState.SentMagicLink) {
      setAuthState(DGAuthState.ResentMagicLink);
      setNextMagicLinkAllowedAt(Date.now() + 120000); // 2 minutes
    } else {
      setAuthState(DGAuthState.SentMagicLink);
      setNextMagicLinkAllowedAt(Date.now() + 90000); // 1.5 minute
    }
    setLastMagicLinkSentAt(Date.now());

    return API.post('passwordless-signin', '/signin', {
      body: {
        "entity": entityId,
        "email": emailParam,
        "language": language,
        "redirectUrl": redirectUrl
      },
    });
  };

  const dgSignInGoogle = () => {
    // Call API to trigger Google OAuth
    setAuthMethod(DGAuthMethod.Google);
    setSignedInMethod("Google");
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
  };

  const dgSignOut = async () => {
    // Sign Out any
    // Clear any resources
    setIsAuthenticated(false);
    setAuthMethod(DGAuthMethod.Unknown);
    setSignedInMethod('');
    setSignedInEmail('');
    // fire signOutAt changes
    setPreSignOutAt(Date.now());
    try {
      // Use Passwordless signout
      await signOut();
    } catch (err) {
      logger.error(`[dgSignOut] Error in passwordless signout: ${err}`);
    }
    
    try {
      //await Auth.signOut();
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        user.signOut();
      }
    } catch (ampErr) {
      logger.error(`[dgSignOut] Error in Amplify signout: ${ampErr}`);
    }
    // Clear any resources
    setIsAuthenticated(false);
    setAuthMethod(DGAuthMethod.Unknown);
    setSignedInMethod('');
    setSignedInEmail('');
    // fire signOutAt changes
    setSignOutAt(Date.now());
  }

  const checkPasswordlessTokenExpired = () => {
    const now = new Date().getTime()
    const exp = new Date((tokensParsed?.idToken.exp || new Date().getTime() / 1000) * 1000).getTime();
    return now > exp;
  }
  const checkAmplifyAuthTokenExpired = () => {
    return !session?.isValid();
  }

  const getBearerToken = () => new Promise<string>((resolve, reject) => {
    if (authState === DGAuthState.SignedInPasswordless) {
      // Use Passwordless tokens
      if (checkPasswordlessTokenExpired()) {
        refreshTokens().then((tokensRefresh) => {
          // Set 
          logger.debug(`[getBearerToken] refreshTokens (Passwordless) success, new expired at: ${tokensRefresh?.expireAt.toISOString() || ""}`);
          const res: string = `Bearer ${tokensRefresh?.idToken}` || '';
          resolve(res);
        }).catch((err: any) => {
          reject(err);
        });
      } else {
        const res: string = `Bearer ${tokens?.idToken}` || '';
        resolve(res);
      }
    } else if (authState === DGAuthState.SignedInSocialGoogle) {
      // Use Amplify Auth tokens (do we need to check expired?)
      if (checkAmplifyAuthTokenExpired()) {
        // Refresh token
        Auth.currentSession().then(session => {
          logger.debug("[getBearerToken] refreshTokens (Amplify Auth) success, new expired at: ");
          setSession(session);
          const res: string = `Bearer ${session?.getIdToken().getJwtToken()}` || '';
          resolve(res);
        }).catch(err => {
          reject(err);
        });
      } else {
        const res: string = `Bearer ${session?.getIdToken().getJwtToken()}` || '';
        resolve(res);
      }
    } else {
      resolve('');
    }
  });

  const resetMagicLinkFlow = () => {
    setAuthState(DGAuthState.Unauthenticated);
    // Not to reset sent time
    //setLastMagicLinkSentAt(null);
  };


  return {
    isAuthenticated,
    checkAuthenticated,
    dgSignInEmail,
    dgSignInGoogle,
    dgSignOut,
    getBearerToken,
    authState,
    signedInEmail,
    signedInMethod,
    nextMagicLinkAllowedAt,
    resetMagicLinkFlow,
    signOutAt,
    preSignOutAt,
  };
}

const DGAuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <DGAuthContext.Provider value={_useDGPasswordless()}>
        {children}
      </DGAuthContext.Provider>
    </>
  );
};

export { DGAuthContextProvider, DGAuthContext };
