import { createTheme } from "@mui/material/styles";
import { typography_landing_h1, typography_dg_h1, typography_dg_h3, typography_body1, typography_body2, typography_button, typography_link, typography_footer } from "./overrides/typography";
//import { button_cta, button_try_other, button_link, button_lng_link, button_social, button_cookie_accept } from "./overrides/button";

import { TypographyOptions } from '@mui/material/styles/createTypography';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    landing_h1: true;
    dg_h1: true;
    dg_h3: true;
    dg_body1: true;
    dg_body2: true;
    dg_button: true;
    dg_link: true;
    dg_footer: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    cta: true;
    social: true;
    try_other: true;
    link: true;
    lng_link: true;
    cookie_accept: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  landing_h1: React.CSSProperties;
  dg_h1: React.CSSProperties;
  dg_h3: React.CSSProperties;
  dg_body1: React.CSSProperties;
  dg_body2: React.CSSProperties;
  dg_button: React.CSSProperties;
  dg_link: React.CSSProperties;
  dg_footer: React.CSSProperties;
}



export const theme = createTheme({
  typography: {
    landing_h1: typography_landing_h1,
    dg_h1: typography_dg_h1,
    dg_h3: typography_dg_h3,
    dg_body1: typography_body1,
    dg_body2: typography_body2,
    dg_button: typography_button,
    dg_link: typography_link,
    dg_footer: typography_footer,
  } as ExtendedTypographyOptions,
  
});
