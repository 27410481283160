import React from "react";
import { Box } from "@mui/material";
//import { NonRedirectPage } from "pages/NonLandingPage/NonRedirectPage";
import { MainContext } from "contexts/MainContext";
import { ShowQRCode } from "./ShowQRCode";
import { getLogger } from "assets/utilities";

function RevealCode() {
  const logger = getLogger("page:RevealCode");
  const EXPIRED_TIME = 210; // 3.5 minutes
  const mainContext = React.useContext(MainContext); 
  const [isShowClaim, setIsShowClaim] = React.useState<boolean>(false); // [TODO
  const [qrcodeUrl, setQRCodeUrl] = React.useState<string>(""); // [TODO
  const [lastQrcodeRetrieval, setLastQrcodeRetrieval] = React.useState<number>(Date.now());
  const [checkCount, setCheckCount] = React.useState<number>(Date.now()); // [TODO
  const [isCodeExpired, setIsCodeExpired] = React.useState<boolean>(false);

  const [utmCampaign, setUtmCampaign] = React.useState<string>(process.env.REACT_APP_GA4_QRCODE_UTM_CAMPAIGN || "");
  const [utmSource, setUtmSource] = React.useState<string>(process.env.REACT_APP_GA4_QRCODE_UTM_SOURCE || "");
  const [utmMedium, setUtmMedium] = React.useState<string>(process.env.REACT_APP_GA4_QRCODE_UTM_MEDIUM || "");
  const qrCodeBoxStyle = {
    width: "100%",
    minHeight: "65vh",
    maxHeight: "600px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    alignContent: "flex-start",
    marginTop: "30px",
  };
  const qrCodeBoxExpiredStyle = {
    width: "100%",
    minHeight: "65vh",
    maxHeight: "600px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    alignContent: "flex-start",
    marginTop: "30px",
    backgroundColor: "#909090",
  };

  const reloadClaimCode = () => {
    mainContext?.retrieveClaimCode().then((code: string) => {
      if (code !== "") {
        // Valid caller to retrieve claim code
        if (isShowClaim !== true) setIsShowClaim(true);
        const utmParams = `utm_campaign=${encodeURIComponent(utmCampaign)}&utm_source=${encodeURIComponent(utmSource)}&utm_medium=${encodeURIComponent(utmMedium)}`;
        const url = window.location.origin + "/?" + utmParams + "&claim_nft=" + encodeURIComponent(code);
        //const encoded = encodeURI(url);
        logger.debug(`QRCode: ${url}`);
        setQRCodeUrl(url);
        setLastQrcodeRetrieval(Date.now());
      }
    }).catch((err: any) => {
      logger.error(`Error in retrieving QR code: ${err}`);
    });
  };

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      reloadClaimCode();
    }, 300);
    return () => clearTimeout(timeout);    
  }, []);

  const checkExpired = () => {
    // Check Expiry
    const now = Date.now();
    const diff = now - lastQrcodeRetrieval;
    const diffInSeconds = Math.floor(diff / 1000);
    //logger.info(`Now is: ${now}, lastretrieval: ${lastQrcodeRetrieval},   diffInSeconds: ${diffInSeconds}, compare with: ${EXPIRED_TIME}`);
    if (diffInSeconds >= EXPIRED_TIME) {
      setIsCodeExpired(true);
    } else {
      setIsCodeExpired(false);
    }
  };

  React.useEffect(() => {
    checkExpired();
  }, [checkCount]);

  React.useEffect(() => {
    
    if (isShowClaim) {
      const interval = setInterval(() => {
        setCheckCount(Date.now());
        reloadClaimCode();
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [isShowClaim]);

  return (
    // <NonRedirectPage>
      <Box sx={(isCodeExpired) ? qrCodeBoxExpiredStyle : qrCodeBoxStyle}>
        { isShowClaim && (
          <ShowQRCode url={qrcodeUrl} backgroundColor={"transparent"} />
        )}
      </Box>
    // </NonRedirectPage>
  );
}

export { RevealCode };
