import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import ImgNFT from "assets/images/nft_hkma30_display.png";
import { useTranslation, Trans } from "react-i18next";
import { MainContext } from "contexts/MainContext";


function SignInTitle() {  
  // eslint-disable-next-line no-unused-vars
  const {t} = useTranslation();
  const mainContext = React.useContext(MainContext);
  
  return (
    <>
      <Box py={1} mx={0}>
        <Grid container alignItems={"center"}>
          <Grid item container xs={12} justifyContent={"center"}>
            <img src={ImgNFT} width={163} alt="HKMA 30th NFT" />
          </Grid>
          {/* <Grid item container xs={12} justifyContent={"center"}>
            <Typography variant="dg_h1">
              {t("signin.title")}                
            </Typography>
          </Grid> */}
          <Grid item container xs={12} mt={1.5} justifyContent={"center"}>
            <Typography variant="dg_h1">
              <Trans 
                i18nKey={mainContext?.redirectClaimEventId !== "" ? "signin.title_claim" : "signin.title_login"}
                components={{ br: <br />, sup: <sup />}} 
              />
            </Typography>
          </Grid>
          <Grid item container xs={12} mt={1} justifyContent={"center"}>
            <Typography variant="dg_body1">
              <Trans 
                i18nKey={mainContext?.redirectClaimEventId !== "" ? "signin.subtitle_claim" : "signin.subtitle"}
                components={{ br: <br />, sup: <sup />}} 
              />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export { SignInTitle };
