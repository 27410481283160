import React, { ReactNode } from "react";
import { Box, Stack, Grid } from "@mui/material";
import { AppMinHeight } from "../../constants";
import { Navbar, Footer } from "../../components";


// implementation
export interface NonRedirectPageProps extends React.ComponentPropsWithoutRef<typeof Box> {
  children: ReactNode;
}

export function NonRedirectPage(prop: NonRedirectPageProps) {
  const containerWhiteStyle = {
    maxWidth: "430px",
    background: "white",
    minHeight: AppMinHeight
  };

  const backgroundWhiteStyle = {
    minHeight: AppMinHeight,
    background: "white",
  };

  return (
    <Stack direction="column">
      <Box sx={backgroundWhiteStyle}>
        <Box sx={containerWhiteStyle }>
          <Grid container justifyContent="center">
            <Grid item xs={12} container justifyContent="center">
              <Navbar isLandingPage={false} />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Box>
                {prop.children}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </Stack>
  );
}
