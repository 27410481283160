import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { proxywallet } from "../graphql/mutations";
import { ProxywalletMutation } from "../API";

export const callProxyWallet = async (data: any, bearerToken: string, success: (data: any) => any, err: (code: number, msg: string) => any) => {
  try {
    const apiRequest = await API.graphql({
      query: proxywallet,
      variables: { input: JSON.stringify(data) },
      authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
      authToken: bearerToken,
    });
    const apiResponse = apiRequest as { data: ProxywalletMutation; errors: any[] };
    //console.log("[callProxyWallet] apiRequest: ", apiRequest);
    const response = JSON.parse(apiResponse.data.proxywallet || "{}");
    
    /*
    Response body documentation
    
    */
    if (response.statusCode === 200) {
      const response_body = response.body;
      if (response_body) {
        success(response_body);
      } else {
        err(400, response_body);
      }
    } else {
      err(response.statusCode, `[callProxyWallet] Error in register: ${response.statusCode}, ${response.body}`);
    }
  } catch (error) {
    //console.log("[callProxyWallet] Error in API call: ", error);
    if (err) {
      err(400, `${error}`);
    }
  }
};

