import React, { Fragment } from "react";
import { Grid, Typography, Box, Stack, LinearProgress } from "@mui/material";
import { MainContext } from "../../contexts/MainContext";
import { useTranslation, Trans } from "react-i18next";
import { NFTViewer } from "components";

const AssetView = () => {
  const mainContext = React.useContext(MainContext);
  const { t } = useTranslation();
  const [noOfItems, setNoOfItems] = React.useState(0);
  const [showClaimProgress, setShowClaimProgress] = React.useState(false);

  React.useEffect(() => {    
    if (mainContext?.isWalletAssetLoaded) {
      // Only check to show Airdrop view if wallet asset is loaded
      if (mainContext?.walletAsset.nft) {
        setNoOfItems(mainContext?.walletAsset.nft.length);
        
        // Check aganist the holding
        const whitelisted = mainContext?.contractAddressLookup.hkma;
        const asset = mainContext?.walletAsset.nft;
        whitelisted.every((element: string) => {
          const anyAsset = asset.find((item: any) => item["contract_address"].toLowerCase() === element.toLowerCase());
          if (anyAsset !== undefined) {
            return false;
          }
        });
        
      } else {
        setNoOfItems(0);
      }
    }
  }, [mainContext?.walletAsset]);

  

  React.useEffect(() => {
    if (mainContext?.claimState === "submitted") {
      setShowClaimProgress(true);
    }
  }, [mainContext?.claimState]);

  return (
    <>      
      <Grid item container xs={12} mt={2} justifyContent={"center"} alignContent={"center"}>
        <Grid item container xs={12} justifyContent={"center"}>
          <Typography variant="dg_h1">
            <Trans i18nKey="profile.asset.title"
              components={{ b: <strong/>, br: <br />, sup: <sup /> }} />
            </Typography>
        </Grid>
        {/* <Grid item container xs={2} mr={1} justifyContent={"flex-end"} alignContent={"center"}>
          <Typography variant="dg_body2" textAlign={"right"}>
            <Trans i18nKey="profile.asset_item_count" values={{ count: noOfItems }} />
          </Typography>
        </Grid>                 */}
      </Grid>

      {showClaimProgress && (
        <Fragment>
          <Grid item container xs={12} ml={1} mt={1} justifyContent={"center"} alignContent={"center"}>
            <Typography variant="dg_body1">
              {t("profile.asset.claim_in_progress")}
            </Typography>
          </Grid>
          <Grid item container xs={12} ml={1} mt={0} justifyContent={"center"} alignContent={"center"}>
            <LinearProgress sx={{}} />
          </Grid>
        </Fragment>
      )}

      <Grid item container xs={12} mt={2} justifyContent={"center"} alignContent={"center"}>
        <Stack direction="column" spacing={2}>
          {
            (mainContext?.walletAsset != null && mainContext?.walletAsset.nft != null && Array.isArray(mainContext?.walletAsset.nft)) &&
              mainContext?.walletAsset.nft.map((asset: any, index: number) => {
              return (
                <Fragment key={`nft-asset-${index}`}>
                  <Box mx={0} my={0} sx={{
                    borderRadius: "0px",
                    background: "#F6F6F6",
                    width: "auto",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  >
                    <Grid container>
                      <Grid item xs={12} container justifyContent={"center"}>
                        <NFTViewer title={asset.title} media={asset.media} />
                      </Grid>
                      <Grid item xs={12} mt={1.5} mb={1.5} container justifyContent={"center"}>
                        <Typography variant="dg_body2">
                          {asset.title || ""}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} mt={0.5} mb={2} container justifyContent={"center"}>
                        <Typography variant="dg_body2">
                          {`#${Number(asset.tokenId || "0x00")}`}
                        </Typography>
                      </Grid>                       */}
                    </Grid>
                  </Box>
                </Fragment>
              );
            })
          }
        </Stack>
      </Grid>

      {(mainContext?.isWalletAssetLoaded) && (noOfItems === 0) && (
        <Grid item container xs={12} ml={1} mt={2} mb={20} justifyContent={"center"} alignContent={"center"}>
          <Typography variant="dg_body1"  mx={2}>
            <Trans i18nKey={"error.expired_code.subtitle"} components={{ b: <strong />, br: <br />}} />
          </Typography>
        </Grid>
      )}

    </>
  )
};

export { AssetView };

