import React, { createContext } from 'react';
import { Passwordless } from './passwordless';
import { 
  PasswordlessContextProvider,
  Passwordless as PasswordlessComponent,
  Fido2Toast,
} from './passwordless/react';

// Amplify related
import { Amplify } from 'aws-amplify';

import PasswordlessController from './PasswordlessController';

import { DGAuthContextProvider } from './DGAuthContext';

// interface PasswordlessAuthContextType {
//   region: string;
// };



const PasswordlessAuthContext = createContext<null>(null);

const PasswordlessAuthContextProvider = (
  { authenticatedRoute, unauthenticatedRoute, children }: 
  { authenticatedRoute: string, unauthenticatedRoute: string, children: React.ReactNode }) => 
{
  // Configure Passwordless
  Passwordless.configure({
    cognitoIdpEndpoint: process.env.REACT_APP_PASSWORDLESS_COGNITO_IDP_REGION || 'ap-southeast-1',
    clientId: process.env.REACT_APP_PASSWORDLESS_COGNITO_CLIENT_ID || '',
    fido2: {
      baseUrl: process.env.REACT_APP_PASSWORDLESS_FIDO2_URL || '',
      authenticatorSelection: {
        userVerification: 'preferred',
      },
      rp: {
        id: "dttd.io",
        name: "DOTTED",
      }
    },
    debug: console.debug,
  });

  // // Only to set Auth for Amplify
  // const AwsConfigAuth = {
  //   region: process.env.REACT_APP_PASSWORDLESS_COGNITO_IDP_REGION || 'ap-southeast-1',
  //   userPoolId: process.env.REACT_APP_PASSWORDLESS_COGNITO_USER_POOL || '',
  //   userPoolWebClientId: process.env.REACT_APP_PASSWORDLESS_COGNITO_CLIENT_ID || '',
  //   oauth: {
  //     domain: process.env.REACT_APP_PASSWORDLESS_COGNITO_DOMAIN || 'auth.passwordless.dttd.wtf',
  //     scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
  //     redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN || '',
  //     redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT || '',
  //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  //   },
  //   authenticationFlowType: 'CUSTOM_AUTH',
  // };
  // //Amplify.Logger.LOG_LEVEL = 'DEBUG';
  // console.log("PasswordlessAuthContextProvider: -- Overriding AwsConfigAuth --");
  // Amplify.configure({ 
  //   Auth: AwsConfigAuth, 
  //   oauth: AwsConfigAuth.oauth, 
  //   aws_cognito_identity_pool_id: process.env.REACT_APP_PASSWORDLESS_COGNITO_IDENTITY_POOL || '',
  // });
  

  // React.useEffect(() => {
  //   console.log('PasswordlessAuthContextProvider: useEffect at init');
  // }, []);

  return (
    <PasswordlessAuthContext.Provider value={null}>
      <PasswordlessContextProvider enableLocalUserCache={true}>
        {/* <PasswordlessComponent /> */}
        <PasswordlessController>
          <DGAuthContextProvider>
            {children}
          </DGAuthContextProvider>
        </PasswordlessController>
        {/* <Fido2Toast /> Add Fido2Toast below App so it is rendered on top */}
      </PasswordlessContextProvider>
    </PasswordlessAuthContext.Provider>
  );
}

export { PasswordlessAuthContext, PasswordlessAuthContextProvider };
