import React, { Fragment, ElementType } from "react";
import { useDGPasswordless, DGAuthState } from "../../dgsdk";
import { NonLandingPage } from "pages/NonLandingPage/main";
import { MainContext } from "contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

import { AppEntityId } from "../../constants";
import { SignInTitle } from "./sections/SignInTitle";
import logoGoogle from "../../assets/images/logo-google.png";
import imgBackgroundHK from "../../assets/images/background_hk.png";

import { TrackableExtLink, CssTextField, CtaButton, SocialButton, CountdownButton, SignoutLink } from "components";
import { useCookies } from "react-cookie";
import { getLogger} from "assets/utilities";

// Material UI
import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, Stack, Typography, Checkbox } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const SignIn = () => {
  const logger = getLogger("page:SignIn");
  const { authState, dgSignInEmail, dgSignInGoogle, isAuthenticated, nextMagicLinkAllowedAt, resetMagicLinkFlow } = useDGPasswordless();

  const mainContext = React.useContext(MainContext);
  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = React.useState(true);
  const [showSentMagicLink, setShowSentMagicLink] = React.useState(false);
  const [formEmail, setFormEmail] = React.useState("");
  const [formTCChecked, setFormTCChecked] = React.useState(false);
  const [submittedEmail, setSubmittedEmail] = React.useState("");
  const [formLanguage, setFormLanguage] = React.useState(mainContext?.language);
  const formError = formTCChecked ? false : true;
  const [cookies, setCookie] = useCookies(['auto_login']);

  const { t } = useTranslation();

  React.useEffect(() => {
    //console.log("authState: ", authState);
    if ((authState === DGAuthState.Unauthenticated) || (authState === DGAuthState.Unknown)) {
      //console.log("Show login form");
      setShowLoginForm(true);
      setShowSentMagicLink(false);
    } else if ((authState === DGAuthState.SentMagicLink) || (authState === DGAuthState.ResentMagicLink)) {
      setShowLoginForm(false);
      setShowSentMagicLink(true);
    } else if ((authState === DGAuthState.SignedInPasswordless) || (authState === DGAuthState.SignedInSocialGoogle)) {
      mainContext?.setHomeRouteTo("/profile");
    } else {
      setShowLoginForm(false);
      setShowSentMagicLink(false);
    }
  }, [authState]);

  React.useEffect(() => {
    setFormLanguage(mainContext?.language);
  }, [mainContext?.language]);

  const signInByEmail = () => {
    //console.log("--- signInByEmail ---");
    const trimmedEmail = formEmail.trim();
    setSubmittedEmail(trimmedEmail); 
    mainContext?.log_event_analytics("attempt_email_signin", { email: trimmedEmail, language: formLanguage });
    let defaultRedirectUrl: string = window.location.origin + "/verify?entity=" + AppEntityId;
    if (formLanguage) {
      defaultRedirectUrl = defaultRedirectUrl + "&language=" + formLanguage;
    }
    //console.log("---- redirectUrl: ", redirectUrl);
    mainContext?.getAsyncRedirectUrl().then((redirectUrl) => {
      logger.debug(`[signInByEmail]: RedirectURL=${redirectUrl}`);
      dgSignInEmail(AppEntityId , trimmedEmail, formLanguage || 'en', redirectUrl || defaultRedirectUrl);
    });
  };

  const signInByGoogle = () => {
    mainContext?.log_event_analytics("attempt_google_signin", {});
    setCookie('auto_login', "true", { path: '/', maxAge: 60 * 10 });
    dgSignInGoogle();
  };

  const onClickCountdown = () => {
    //console.log("--- onClickCountdown ---");
    mainContext?.log_event_analytics("attempt_resend_email", { email: submittedEmail, language: formLanguage });
    let defaultRedirectUrl: string = window.location.origin + "/verify?entity=" + AppEntityId;
    if (formLanguage) {
      defaultRedirectUrl = defaultRedirectUrl + "&language=" + formLanguage;
    }
    mainContext?.getAsyncRedirectUrl().then((redirectUrl) => {
      logger.debug(`[Resend]: Resend-RedirectURL=${redirectUrl}`);
      dgSignInEmail(AppEntityId , formEmail.trim(), formLanguage || 'en', redirectUrl || defaultRedirectUrl);
    });
  };

  const dividerStyle = {
    width: "100%",
    textAlign: "center",
    "&.MuiDivider-wrapper": {
      paddingBottom: "5px !important",
    },
  };

  return (
    <NonLandingPage>
      
      {showLoginForm && (
        <Box display={"flex"} alignContent={"start"} mx={2}>
          <Fragment>
            <FormControl sx={{ padding: "0" }}
              required
              error={formError}
              component="fieldset"
              variant="standard"
            >
              <Grid container px={0} py={0} mx={0} my={0}>
                <Grid item xs={12} lg={12} mt={0} container justifyContent={"center"}>
                  <SignInTitle />
                </Grid>
                
                <Grid item xs={12} lg={12} mt={1} mx={0} container justifyContent={"center"}>
                  <CssTextField
                    fullWidth 
                    //sx={{ maxWidth: "350px" }}                  
                    label={t("signin.email_label")}
                    placeholder={t("signin.email_placeholder")}
                    value={formEmail}
                    onChange={(newValue) => { if (showLoginForm) setFormEmail(newValue.target.value) }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={12} mt={2} mb={1} mx={0} container justifyContent={"center"}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={formTCChecked} onChange={() => setFormTCChecked(!formTCChecked)} name="tc"
                        sx={{
                          color: "#571A64",
                          '&.Mui-checked': {
                            color: "#571A64",
                          },
                        }} />
                    }
                    label={
                      <Typography variant="dg_body2" sx={{
                        color: "var(--primary-darkgrey, #303030)",
                        fontSize: "12px",
                        fontWeight: "400",
                        }}>
                        <Trans i18nKey="signin.tc"
                          components={{
                            br: <br />,
                            tc: <Typography
                              component={TrackableExtLink as ElementType}
                              to="https://www.dttd.io/terms-and-conditions"
                              target="_blank"
                              rel="noreferrer"
                              variant="dg_body2"
                              sx={{
                                color: "#126BA7",
                                fontSize: "12px",
                                textDecoration: "underline",
                              }}
                            />,                  
                            pp: <Typography
                              component={TrackableExtLink as ElementType}
                              to="https://www.dttd.io/privacy-policy"
                              target="_blank"
                              rel="noreferrer"
                              variant="dg_body2"
                              sx={{
                                color: "#126BA7",
                                fontSize: "12px",
                                textDecoration: "underline",
                              }}
                            />,
                          }} 
                        />
                      </Typography>
                    }
                  />
                </Grid>              
                <Grid item xs={12} lg={12} mt={1} mx={0} container justifyContent={"center"}>
                  <CtaButton fullWidth onClick={() => signInByEmail()}
                    disabled={!formTCChecked || formEmail === ""}
                  >
                    <Typography variant="dg_button">
                      {t("signin.signin_button")}
                    </Typography>
                  </CtaButton>
                </Grid>
                
                <Grid item xs={12} lg={12} mx={0} container justifyContent={"center"}>                
                  {formTCChecked ? (<FormHelperText sx={{marginTop: "0px"}}>&nbsp;</FormHelperText>) : (
                    <FormHelperText sx={{marginTop: "0px"}}>{t("signin.form_error_tc")}</FormHelperText>
                  )}
                </Grid>
                
              </Grid>
              <Grid item xs={12} lg={12} mx={0} mt={1} mb={1} container justifyContent={"center"}  alignContent={"center"} alignItems={"center"} >
                <Divider sx={dividerStyle}>
                  <Typography variant="dg_body2" sx={{ color: "var(--primary-grey, #555555)" }}>{t("signin.continue")}</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} lg={12} mt={2} mx={0} mb={3} container justifyContent={"center"}>
                <SocialButton
                  fullWidth
                  onClick={() => signInByGoogle()}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <img src={logoGoogle} alt="Google" width={16} height={16} />
                    <Typography variant="dg_h3" sx={{ color: "#303030", fontSize: "14px", fontWeight: "500" }}>{t("signin.google")}</Typography>
                  </Stack>
                </SocialButton>
              </Grid>
            </FormControl>            
          </Fragment>
        </Box >
      )}
      
      {showSentMagicLink && (
        <Grid container mx={0}>
          <Grid mx={0} container>
            <Grid item xs={12} lg={12} container justifyContent={"center"}
              sx={{
                width: "100%",
                height: "212px",
                background: "url(" + imgBackgroundHK + ")",
                backgroundSize: "cover",
              }}
            >
              <Box sx={{
                width: "100%",
                background: "linear-gradient(90deg, #303030 0.16%, rgba(48, 48, 48, 0.30) 99.84%)",
                height: "212px",
              }} />
            </Grid>
            <Grid item xs={12} lg={12} mt={2} mx={2} container justifyContent={"center"}>
              <Typography variant="dg_h1">
                <Trans i18nKey={"signin.sentml.title"}
                  components={{
                    br: <br />,
                    b: <strong />,
                  }} />
              </Typography>
            </Grid>
            <Grid item xs={12} lg={12} mt={2} mx={2} container justifyContent={"center"}>
              <Stack direction={"column"} spacing={1} alignItems={"center"}>
                <Typography variant="dg_body1">
                  <Trans i18nKey="signin.sentml.sent_body_upper"
                    components={{
                      br: <br />,
                      b: <strong />,
                      email: <span style={{color: '#571A64', fontSize: '14px', fontWeight: 'bold'}} />,
                    }}
                    values={{email: submittedEmail}}
                  />
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12} mt={3} mx={2} container justifyContent={"center"}>
              <Box sx={{ width: "100%", background: "#F6F6F6", padding: "8px", display: "flex", alignContent: "center", justifyContent: "center" }}>
                <Typography variant="dg_body1" sx={{lineHeight: '24px', fontWeight: "600", color: "#555555" }}>
                  <Trans i18nKey="signin.sentml.sent_body_bottom"
                    components={{
                      br: <br />,
                      i: <i />,
                      outline: <span style={{outline: '1px solid #E2E2E2', background: "#fff", padding: '5px 8px'}} />
                    }}
                  />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={12} mt={3} mx={2} container justifyContent={"center"}>                
              <CountdownButton 
                targetDate={nextMagicLinkAllowedAt} 
                label={t("signin.sentml.resend_button")} 
                onClick={onClickCountdown} />
            </Grid>
            <Grid item xs={12} lg={12} mt={2} mb={3} mx={2} container justifyContent={"center"}>
              <SocialButton fullWidth
                sx={{ border: "transparent"}}
                onClick={() => { 
                  mainContext?.log_event_analytics("try_another_email", {});
                  resetMagicLinkFlow(); 
                  navigate("/signin"); 
                }}
              >
                <Typography variant="dg_button" sx={{ color: "#2B2B2B", textDecoration: 'underline' }}>
                  {t("signin.sentml.try_another_button")}
                </Typography>
              </SocialButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      
      { isAuthenticated && (
        <Fragment>
          <Grid container px={0} py={0} mx={0} my={0}>
            <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
              {/* <Button variant="contained" color="primary" onClick={() => Auth.signOut()}>
                {t("Sign Out")}
              </Button> */}
              <SignoutLink />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </NonLandingPage>
  );
};

export { SignIn };