import React, { Fragment } from 'react';
import './App.css';

import { theme } from "./assets/themes";

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import TrackableApp from './TrackableApp';


function App() {
  
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',        
        width: "100%",
      }}
    >
      <ThemeProvider theme={theme}>
        <Fragment>
          <BrowserRouter>
            <TrackableApp />
          </BrowserRouter>      
        </Fragment>
      </ThemeProvider>
    </div>
  );
}

export default App;
