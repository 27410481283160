import React from "react";
import { Box, Card, Grid, Typography, } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MainContext } from "../../contexts/MainContext";
import { useNavigate } from "react-router-dom";
import { NFTViewer, SocialButton } from "components";

type Props = { indicateClose: boolean };
export type Ref = HTMLDivElement;

// eslint-disable-next-line react/prop-types, react/display-name
const PopupCompleted = React.forwardRef<Ref, Props>(({ indicateClose, ...rest }, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mainContext = React.useContext(MainContext);
  const [asset, setAsset] = React.useState<any>({});

  React.useEffect(() => {
    if (indicateClose) {
      mainContext?.setHomeRouteTo("/profile");
      navigate("/profile");
    }
  }, [indicateClose]);

  React.useEffect(() => {
    if (mainContext?.walletAsset.nft !== undefined) {
      if (mainContext?.walletAsset.nft.length > 0) {
        // Display NFT claimed
        for (let i = 0; i < mainContext?.walletAsset.nft.length; i++) {
          if (mainContext?.contractAddressLookup.hkma.includes(mainContext?.walletAsset.nft[i].contract_address.toLowerCase())) {
            setAsset(mainContext?.walletAsset.nft[i]);
            break;
          }
        }
      }
    }
  }, [mainContext?.walletAsset]);

  const popupStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "350px",
    height: "620px",
    flexShrink: "0",
    borderRadius: "0px",
    background: "#FFF",
    boxShadow: "2px",
  };

  return (
    <>
      <Card
        ref={ref}
        {...rest}
        sx={popupStyle}
      >
        <Box mx={2} my={2}>
          <Grid container alignItems={"center"}>
            <Grid item mt={1} xs={12} container justifyContent={"center"}>
              <NFTViewer title="" media={asset.media} sx={{ minWidth: "311px", minHeight: "311px" }} />
            </Grid>
          </Grid>
          <Box mx={0} my={0} sx={{
                    borderRadius: "0px",
                    background: "#F6F6F6",
                    width: "100%",
                    height: "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                  }}>
            <Grid container alignItems={"center"}>
              <Grid item mt={2} xs={12} container justifyContent={"center"}>
                <Typography variant="dg_body2" sx={{ fontWeight: "600"}}>
                  HKMA 30th Anniversary Commemorative NFT
                </Typography>
              </Grid>
              <Grid item mt={1} mb={2} xs={12} container justifyContent={"center"}>
                <Typography variant="dg_body2">
                  {asset.title || ""}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container alignItems={"center"}>
            <Grid item mt={3} xs={12} container justifyContent={"center"}>
              <Typography variant="dg_h1">
                {t("profile.claim.title_congrats")}
              </Typography>
            </Grid>
            <Grid item mt={3} xs={12} container justifyContent={"center"}>
              <Typography variant="dg_body1">
                {t("profile.claim.subtitle_congrats")}
              </Typography>
            </Grid>
            <Grid item mt={3} mb={4} xs={12} container justifyContent={"center"}>
              <SocialButton fullWidth onClick={() => {
                mainContext?.setHomeRouteTo("/profile");
                navigate("/profile");
              }}>
                <Typography variant="dg_body1" sx={{ textDecoration: 'none', fontWeight: '500' }}>
                  {t("profile.claim.button_view")}
                </Typography>
              </SocialButton>
            </Grid>
          </Grid>

        </Box>
      </Card>
    </>
  );
});

export { PopupCompleted };
