import React from "react";
import { NonLandingPage } from "pages/NonLandingPage/main";
import { MainContext } from "contexts/MainContext";
import { DisplayWallet, SocialButton, CtaButton } from "components";
import { AssetView } from "./AssetView";
import { Grid, Typography, Divider, Box, Stack } from "@mui/material";
import { useDGPasswordless, DGAuthState } from "dgsdk";
import { useTranslation, Trans } from "react-i18next";
import { getLogger } from "assets/utilities";

import ImgDTTDApp from "assets/images/dttd-app.png";

const Profile = () => {
  const logger = getLogger("page:Profile");
  const mainContext = React.useContext(MainContext);
  const { isAuthenticated, dgSignOut, checkAuthenticated } = useDGPasswordless();
  const { t } = useTranslation();
  // React.useEffect(() => {
  //   console.log("[Profile] mainContext?.walletAddress: ", mainContext?.walletAddress);
  // }, [mainContext?.walletAddress]);

  // React.useEffect(() => {
  //   if (mainContext?.isWalletAssetLoaded) {
  //     if (mainContext?.walletAsset?.nft.length === 0) {
  //       // Navigate to welcome page
  //       mainContext?.setHomeRouteTo("/welcome");
  //     }
  //   }
  // }, [mainContext?.walletAsset]);

  React.useEffect(() => {
    checkAuthenticated().then((isAuth) => {
      if (isAuth) {
        mainContext?.queryWallet().then((wallet_data) => {
          mainContext?.queryWalletAsset(wallet_data.walletAddress).then((wallet_asset_data) => {
            logger.debug(`[checkAuthenticated] wallet asset loaded: ${JSON.stringify(wallet_asset_data)}`);
          }).catch((wallet_asset_err) => {
            logger.warn("[checkAuthenticated] Error in wallet asset query: ", wallet_asset_err);
          });
        }).catch((wallet_err) => {
          logger.warn("[checkAuthenticated] Error in wallet query: ", wallet_err);
        });
      }
    }).catch((err) => {
      logger.warn("[checkAuthenticated] Error: ", err);
    });
  }, [checkAuthenticated]);

  return (
    <NonLandingPage>
      <Grid container>
        <Grid item xs={12} mt={0} container justifyContent="center">
          <Box sx={{width: "100%", background: "#F6F6F6", paddingTop: "16px", paddingBottom: "16px",
              justifyContent: "center", display: "flex", 
              }}>
            <DisplayWallet walletAddress={mainContext?.walletAddress} />
          </Box>
        </Grid>
        <Grid item xs={12} mt={0} mx={2} container justifyContent="center">
          <AssetView />
        </Grid>

        <Grid item xs={12} mt={5} mx={2} container justifyContent="center">
          <Box sx={{ width: "100%"}}>
            <Divider />
          </Box>
        </Grid>

        <Grid item xs={12} mt={4} mx={2} container justifyContent="center">
          <Typography variant="dg_h1">
            <Trans i18nKey={"profile.dttd.title"}
              components={{ b: <strong />, br: <br />}} />
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1.5} container justifyContent="center">
          <Typography variant="dg_body1" mx={2}>
            {t("profile.dttd.body")}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={3} container justifyContent="center">
          <Box sx={{ 
            width: "91%", 
            border: "1px solid #E2E2E2", 
            background: "rgba(255, 255, 255, 0.70)",
            display: "flex",
            justifyContent: "center",
            }}>
            <Stack mx={3} my={2.2} direction="column">
              <img src={ImgDTTDApp} alt="DTTD App" style={{ width: "100%" }} />
              <CtaButton sx={{marginTop: "16px" }} fullWidth onClick={() => window.open("https://hop.dttd.app/hkma30nft", "_blank")}>
                <Typography variant="dg_button">
                  {t("profile.dttd.button")}
                </Typography>
              </CtaButton>
            </Stack>
          </Box>
        </Grid>

        {isAuthenticated && (
          <Grid item xs={12} mt={3} mb={3} mx={2} container justifyContent="center">
            <SocialButton fullWidth sx={{ border: "transparent"}} onClick={() => dgSignOut()}>
              <Typography variant="dg_body1" sx={{ textDecoration: 'underline'}}>
                {t("signout")}
              </Typography>
            </SocialButton>
          </Grid>
          )}
      </Grid>
    </NonLandingPage>
  );
};

export { Profile };