import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { Grid, Typography, Button } from "@mui/material";
//import imgError from "../../assets/images/error.png";
import imgNFT from "assets/images/nft_hkma30_display.png";
import { NonLandingPage } from "pages/NonLandingPage/main";
import { SocialButton } from "components";
import { MainContext } from "contexts/MainContext";

function VerifyFailed() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainContext = React.useContext(MainContext);

  const clickCTA = () => {
    mainContext?.resetMagicLinkInvalid();
    //navigate("/");
    window.location.href = "/";
  };

  return (
    <NonLandingPage>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} lg={12} mt={4} container justifyContent={"center"}>
          <img src={imgNFT} alt="error" width={"310px"} />
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Typography variant="dg_h1">
            {t("verify.failed")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} mt={2} container justifyContent={"center"}>
          <Typography variant="dg_body1">
            <Trans i18nKey="verify.failed_details" 
              components={{ support: <a rel="noopener noreferrer" href="mailto:support@gryfyn.io" /> }} />
          </Typography>
        </Grid>
        <Grid item xs={12} mt={3} container justifyContent="center">
          <SocialButton fullWidth sx={{ border: "transparent"}} onClick={() => { clickCTA(); }}>
            <Typography variant="dg_body1" sx={{ textDecoration: 'underline'}}>
              {t("verify.button_cta")}
            </Typography>
          </SocialButton>
        </Grid>
      </Grid>
    </NonLandingPage>
  );
}

export { VerifyFailed };
