import { useCallback, useEffect, useRef, useMemo } from 'react';

export function useTimeoutHook(callback: (args: any) => void, delay: number) {
  const timeoutRef = useRef<number | undefined | null>();
  const callbackRef = useRef<(args: any) => void>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => window.clearTimeout(timeoutRef.current as number);
  }, []);

  const memoizedCallback = useCallback(
    (args: any) => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = window.setTimeout(() => {
        timeoutRef.current = null;
        callbackRef.current?.(args);
      }, delay);
    },
    [delay, timeoutRef, callbackRef]
  );

  return useMemo(() => [memoizedCallback], [memoizedCallback]);
}