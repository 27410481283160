import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDGPasswordless } from './DGAuthContext';
import { useTimeoutHook } from 'assets/utilities';

export const ProtectedRoutes = ({ unauthenticatedRoute } : { unauthenticatedRoute: string }) => {
  const { pathname } = useLocation();
  const { checkAuthenticated } = useDGPasswordless();
  const navigate = useNavigate();
  const [timeout] = useTimeoutHook(() => {
    if (pathname !== "") {
      checkAuthenticated().then((result) => {
        //setIsValid(result);
        //console.log("[ProtectedRoutes] loggedIn: ", result); 
        if (!result) {
          navigate(unauthenticatedRoute, { replace: true });
          return <></>;
        }
      });
    }
  }, 300);

  React.useEffect(() => {
    if (pathname !== "") {
      timeout({});
    }
  }, [pathname]);
  return <Outlet/>;
}
