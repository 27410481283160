import React, { Fragment } from 'react';
import { useCountdown } from './useCountdown';
import { Button, Typography, Stack } from '@mui/material';
import { CtaButton } from './CtaButton';

// eslint-disable-next-line react/prop-types
const CountdownButton = ({ targetDate, label, onClick } : { targetDate: number, label: string, onClick: () => void}) => {
  // eslint-disable-next-line no-unused-vars
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const [totalSeconds, setTotalSeconds] = React.useState(0);

  // React.useEffect(() => {
  //   console.log("CountdownButton: targetDate: ", targetDate);
  //   console.log("seconds: ", seconds);
  // }, []);

  React.useEffect(() => {
    setTotalSeconds(minutes * 60 + seconds);
  }, [minutes, seconds]);
  
  return (      

    <CtaButton
      fullWidth
      onClick={() => onClick()} 
      disabled={(totalSeconds > 0)}>
      <Stack direction="row" spacing={0}>
        <Typography variant="dg_button">
          {label}    
        </Typography>
        {totalSeconds > 0 && (
          <Fragment>
            <Typography variant="dg_button" ml={0.5}>
              {"("}
            </Typography>
            <Typography variant="dg_button">
              {totalSeconds}
            </Typography>
            <Typography variant="dg_button"ml={0.2}>
              {"s)"}
            </Typography>
          </Fragment>
        )}
      </Stack>
    </CtaButton>
  );
  
};

export { CountdownButton };
