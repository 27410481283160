import { Box, Stack, Typography } from "@mui/material";
import { formatWalletAddress } from "../../assets/utilities";
import { CopyToClipboardButton } from "../../components/Buttons/CopyToClipboardButton";
import { useTranslation } from "react-i18next";
import ImgAvatar from "../../assets/images/persona-avatar.png";

interface DisplayWalletProps {
  walletAddress: string | undefined;
}

// eslint-disable-next-line react/prop-types
const DisplayWallet:React.FC<DisplayWalletProps> = ({walletAddress}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <img src={ImgAvatar} width={46} alt="Avatar" />
      <Stack direction="column" spacing={0.5} alignContent={"flex-start"}>
        <Typography variant="dg_body2">
          {t("wallet_address")}
        </Typography>
        <Box
          sx={{
            borderRadius: "0px",
            border: "1px solid #E2E2E2",
            //background: "rgba(43, 43, 43, 0.06)",
            background: "#FFFFFF",
          }}
        >
          <Stack direction="row" ml={0} my={0} alignItems="center" justifyContent={"flex-end"}>
            <Typography variant="dg_body1" mx={1.5}>
              {formatWalletAddress(walletAddress || "")}
            </Typography>
            <CopyToClipboardButton data={walletAddress || ""} />
          </Stack>
        </Box>
      </Stack>
    </Stack>
    
  );
};

export { DisplayWallet };
