import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContext } from "contexts/MainContext";
import { useDGPasswordless, DGAuthState } from "dgsdk";
import { AppMinHeight } from "../../constants";
import { Navbar, Footer, CtaButton } from "../../components";

import { Trans } from "react-i18next";

import { Backdrop, CircularProgress, Box, Grid, Stack, CssBaseline, Typography } from "@mui/material";
import imgBackground from "../../assets/images/banner_home_bg.png";
import imgNFT from "../../assets/images/nft_hkma30_display.png";

const Home = () => {
  const mainContext = React.useContext(MainContext);
  const { isAuthenticated, authState } = useDGPasswordless();
  if (mainContext === null) {
    throw Error("No MainContext provided!");
  }
  const navigate = useNavigate();

  const [showPage, setShowPage] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);

  const clickCTA = () => {
    if ((authState === DGAuthState.SignedInPasswordless) || (authState === DGAuthState.SignedInSocialGoogle)) {
      navigate("/profile");
    } else {
      navigate("/signin");
    }
  };

  React.useEffect(() => {
    // Route to page
    if (mainContext.homeRouteTo) {
      navigate(mainContext.homeRouteTo);
    }
  }, [mainContext.homeRouteTo]);

  React.useEffect(() => {
    if (mainContext.loadingWallet) {
      setOpenLoading(true);
    } else {
      setOpenLoading(false);      
    }
  }, [mainContext.loadingWallet]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setShowPage(true);
    } else {
      setShowPage(false);
    }
  }, [isAuthenticated]);  

  const backgroundLandingStyle = {
    minHeight: AppMinHeight,
    backgroundImage: `url(${imgBackground})`,
    backgroundSize: "cover", 
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    //lightgray 50% / cover no-repeat;`,
  };

  const containerLandingStyle = {
    maxWidth: "430px",
    background: "linear-gradient(90deg, #303030 0.16%, rgba(48, 48, 48, 0.30) 99.84%);", 
    minHeight: AppMinHeight
  };

  const nftImgBoxStyle = {
    height: "98.5%",
    border: "1px solid rgba(255, 255, 255, 0.40)",
    background: "rgba(65, 60, 84, 0.70)",
    boxShadow: "0px 0px 16px 0px rgba(255, 255, 255, 0.25)",
    backdropFilter: "blur(2px)",
  };

  return (
    <>
      <Stack direction="column">
        <Box sx={backgroundLandingStyle}>
          <Box sx={containerLandingStyle}>
            <Grid container alignSelf={"start"} justifyContent="center">
              <Grid item xs={12} container justifyContent="center">
                <Navbar isLandingPage={true} />
              </Grid>
              <Grid item xs={12} container mt={4} mx={2} justifyContent="center">
                <Box sx={nftImgBoxStyle}>
                  <img src={imgNFT} width={"100%"} alt="HKMA 30th NFT" />
                </Box>
              </Grid>
              <Grid item xs={12} container mt={2} mx={2} justifyContent="center">
                <Typography variant="landing_h1">
                  <Trans i18nKey="landing.title" components={{ br: <br />, sup: <sup />, b: <b /> }} />
                </Typography>
              </Grid>
              <Grid item xs={12} container mt={3} mx={2} justifyContent="center">
                <CtaButton fullWidth onClick={() => clickCTA() }>
                  <Typography variant="dg_button">
                    <Trans i18nKey={(mainContext?.redirectClaimEventId !== "") ? "landing.cta_claim" : "landing.cta_login"} 
                      components={{ br: <br />, sup: <sup />, b: <b /> }} />
                  </Typography>
                </CtaButton>
              </Grid>
              
            </Grid>
          </Box>

        </Box>
        <Footer />
      </Stack>

      

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export { Home };