import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Box, Card, Button, Grid, Typography, Stack, Backdrop, CircularProgress, Modal } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../contexts/MainContext";
import imgNFT from "../../assets/images/nft_hkma30_display.png";
import { NonLandingPage } from "../NonLandingPage/main";
import { CtaButton } from "../../components";
import { useTimeoutHook, getLogger } from "assets/utilities";
import { PopupCompleted } from "./PopupCompleted";

const ClaimNFT = () => {
  const logger = getLogger("page:ClaimNFT");
  const { t } = useTranslation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const mainContext = React.useContext(MainContext);
  const [openLoading, setOpenLoading] = React.useState(true);
  const [claimImgUrl, setClaimImgUrl] = React.useState("");
  const [claimNftName, setClaimNftName] = React.useState("");
  //const [claimWhitelistContract, setClaimWhitelistContract] = React.useState("");
  const [showClaimProgress, setShowClaimProgress] = React.useState(false);
  //const [showClaimCompleted, setShowClaimCompleted] = React.useState(false);
  const [showAlreadyClaimed, setShowAlreadyClaimed] = React.useState(false);
  const [showFullyClaimed, setShowFullyClaimed] = React.useState(false);
  const [openPopupCompleted, setOpenPopupCompleted] = React.useState(false);
  const [isPopupClose, setIsPopupClose] = React.useState(false);
  //const [isWalletAssetLoaded, setIsWalletAssetLoaded] = React.useState(false);
  const checkAnyClaimId = (args: any) => {
    if (mainContext?.redirectClaimEventId !== "") {
      // Query wallet
      logger.debug(`[checkAnyClaimId] query wallet info with redirectClaimEventId: ${mainContext?.redirectClaimEventId}`);
      mainContext?.queryWallet().then((wallet_data) => {
        // Wallet Address is ready
        //setIsWalletAssetLoaded(true);
        logger.debug("[checkAnyClaimId] wallet data loaded");
        retrieveEventCode();
      }).catch((wallet_err) => {
        logger.warn(`[checkAnyClaimId] Error in queryWallet: ${wallet_err}`);
        mainContext?.updateCompleteClaimEvent();
        navigate("/error-invalid");
      });
      return true;
    } else {
      mainContext?.setHomeRouteTo("/profile");
    }
  };
  const [timeout] = useTimeoutHook(checkAnyClaimId, 200);

  const handleClaimPopupClose = () => {
    mainContext?.updateCompleteClaimEvent();
    setOpenPopupCompleted(false);
    setIsPopupClose(true);
    setShowClaimProgress(false);
    mainContext?.setHomeRouteTo("/profile");
  };

  

  // const closeBtnClick = () => {
  //   mainContext?.updateCompleteClaimEvent();
  // };

  // const claimScannedNFT = async () => {
  //   mainContext?.claimScannedNFT();

  //   // Show Progress
  //   setShowClaimProgress(true);

  //   // Polling to check wallet asset to see any balance on whitelisted collection
  //   setPollCounter(0);
  // };

  const claimScannedNFT = async () => {
    //console.log("[ClaimPopup] claiming NFT...");
    mainContext?.claimScannedNFT().then((claim_data) => {
      // On Claim Submitted -> Show Progress and Route to Profile?
      mainContext?.updateCompleteClaimEvent();
      setShowClaimProgress(true);

      // Start the polling counter
      setPollCounter(0);
    }).catch((claim_err) => {
      logger.error(`[claimScannedNFT] Error in claiming NFT: ${claim_err}`);
    });
  };

  const checkClaimedAsset = async () => {
    try {
      const claimed = await mainContext?.refreshWalletAssetAfterClaim(mainContext?.contractAddressLookup.hkma);
      //console.log("[ClaimPopup] checkClaimedAsset-claimed: ", claimed);
      if (claimed) {
        // Popup Claim Completed
        setOpenPopupCompleted(true);
        
        // Stop the timer
        setPollCounter(100);
      }
    } catch (err) {
      logger.error(`[checkClaimedAsset] Error in refresh asset: ${err}`);
    }
  };

  const clickCTA = async () => {
    if (showFullyClaimed) {
      // Close the popup form
      mainContext?.updateCompleteClaimEvent();
      navigate("/profile");
    } else {
      if (showAlreadyClaimed) {
        // Close the popup form
        mainContext?.updateCompleteClaimEvent();
        navigate("/profile");
      } else {
        await claimScannedNFT();
      }
    }
  };

  const [ pollCounter, setPollCounter] = React.useState(100);
  const pollCounterValid = pollCounter < 10;

  const retrieveEventCode = () => {
    // Query event info for display
    mainContext?.queryEventInfo(mainContext?.redirectClaimEventId).then((event_data) => {
      setOpenLoading(false);
      //console.log("[ClaimPopup] event_data: ", event_data);
      logger.info(`[retrieveEventCode] event_data: ${event_data}`);
      if (event_data !== undefined) {
        const data = JSON.parse(event_data);
        setClaimNftName(data?.event_info?.event_title);
        setClaimImgUrl(data?.event_info?.event_img_url);
        //setClaimWhitelistContract(data?.event_info?.contract_details?.contract_address)

        // Check on event stats
        const event_stats = data?.event_info?.event_stats;
        const supply = event_stats?.supply ?? 1039;
        const curr_count = event_stats?.value ?? 0;
        const num_supply: number = +supply;
        const num_curr_count: number = +curr_count;
        logger.info(`[retrieveEventCode] Stats: (curr count: ${num_curr_count} / supply: ${num_supply})`);
        if (num_curr_count >= num_supply) {
          setShowFullyClaimed(true);
          mainContext?.updateCompleteClaimEvent();
        } else {
          setShowFullyClaimed(false);
        }
        logger.info(`[retrieveEventCode] Stats: (curr count: ${curr_count} / supply: ${supply}), is fully claimed: ${showFullyClaimed}`);


        //console.log("[ClaimPopup] image: ", data?.event_info?.event_img_url);
        //console.log("[ClaimPopup] name: ", data?.event_info?.event_title);
        if (data?.claim_info?.valid_to_claim === true) {
          setShowAlreadyClaimed(false);
        } else {
          setShowAlreadyClaimed(true);
          mainContext?.updateCompleteClaimEvent();
        }
      }

      // Dev
      //setPollCounter(0);
    }, (event_err) => {
      setOpenLoading(false);
      mainContext?.updateCompleteClaimEvent();
      if (event_err === "Code expired") {
        logger.info("Code is expired");
        // Redirect to expired page
        navigate("/error-expired");
      } else {
        logger.warn(`[retrieveEventCode] Error in callProxyEventInfo: ${event_err}`);
        // Redirect to code error page
        navigate("/error-invalid");
      }
    });
  };

  // React.useEffect(() => {
  //   if (mainContext?.redirectClaimEventId !== "") {
  //     if (isWalletAssetLoaded) {
  //       retrieveEventCode();
  //     } else {
  //       mainContext?.queryWallet().then((wallet_data) => {
  //         setIsWalletAssetLoaded(true);
  //         retrieveEventCode();
  //       }).catch((wallet_err) => {
  //         console.log("[ClaimPopup] Error in queryWallet: ", wallet_err);
  //         mainContext?.updateCompleteClaimEvent();
  //         navigate("/error-invalid");
  //       });
  //     }
  //   }
  // }, [mainContext?.redirectClaimEventId]);
  
  React.useEffect(() => {
    if (pollCounterValid) {
      const intervalId = setInterval(() => {
        setPollCounter((t) => t + 1);
      }
      , 20000);
      return () => clearInterval(intervalId);
    }
  }, [pollCounterValid]);

  React.useEffect(() => {
    if (pollCounterValid) {
      // Check wallet asset
      checkClaimedAsset().then(() => {
        //console.log(`Done ${pollCounter} checking`);
      });
      // Skip wallet check and just notify user
      //checkWalletAsset(mainContext.walletAddress);
      //console.log("checking counter: ", pollCounter);
      if (pollCounter >= 6) {
        // Close the popup form
        setPollCounter(100);

        // Set as claim
        mainContext?.updateCompleteClaimEvent();

        // Force to go back to profile with browser refrehsing
        //window.location.href = "/profile";
        mainContext?.setHomeRouteTo("/profile");
      }
    }
  }, [pollCounter]);

  React.useEffect(() => {
    timeout({});
  }, []);

  const progressStyle = {
    "&.MuiCircularProgress-colorPrimary": {
      color: "rgba(255, 255, 255, 0.84) !important",
    }
  };

  return (
    <NonLandingPage>
      <Grid container>
        { (!openLoading) && (
        <Fragment>
          <Grid item xs={12} mt={1} container justifyContent={"center"}>
            <img src={imgNFT} width={253} alt="HKMA30" />
          </Grid>
          <Grid item xs={12} mt={1.5} container justifyContent={"center"}>
            <Typography variant="dg_h1">
                <Trans i18nKey={showFullyClaimed ? t("profile.claim.title_fully_claimed") : (showAlreadyClaimed ? t("profile.claim.title_already_claimed") : t("profile.claim.title_welcome"))} 
                  component={{ b: <strong />, br: <br /> }}
                />
              </Typography>
          </Grid>
          <Grid item xs={12} mt={1.5} container justifyContent={"center"}>
            <Typography variant="dg_body1">
              <Trans i18nKey={showFullyClaimed ? t("profile.claim.subtitle_fully_claimed") : (showAlreadyClaimed ? t("profile.claim.subtitle_already_claimed") : t("profile.claim.subtitle_welcome"))} 
                component={{ b: <strong />, br: <br /> }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} mt={3} mx={2} container justifyContent={"center"}>
            <CtaButton fullWidth onClick={() => clickCTA()} disabled={showClaimProgress}>
              {showFullyClaimed ? (
                <Typography variant="dg_body1" sx={{ fontWeight: "500", color: "var(--primary-white, #FFF);" }}>
                  {t("profile.claim.button_fully_claimed")}
                </Typography>
              ) : (
                <Fragment>
                  {showAlreadyClaimed ? (
                    <Typography variant="dg_body1" sx={{ fontWeight: "500", color: "var(--primary-white, #FFF);" }}>
                      {t("profile.claim.button_view")}
                    </Typography>
                  ) : (
                    <Fragment>
                      {showClaimProgress ? (
                        <Stack direction={"row"} alignItems={"center"}>
                          <Typography variant="dg_body1" sx={{ fontWeight: "500", color: "var(--primary-white, #FFF);" }}>
                            {t("profile.claim.button_claim_progress")}
                          </Typography>
                          <Box sx={{ transform: 'scale(0.4)', marginTop: '4px !important' }}>
                            <CircularProgress sx={progressStyle} />
                          </Box>
                        </Stack>
                      ) : (
                        <Typography variant="dg_body1" sx={{ fontWeight: "500", color: "var(--primary-white, #FFF);" }}>
                          {t("profile.claim.button_welcome")}
                        </Typography>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}

              
              
            </CtaButton>
          </Grid>
        </Fragment>
        )}
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPopupCompleted}
        onClose={handleClaimPopupClose}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <PopupCompleted indicateClose={isPopupClose} />
      </Modal>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </NonLandingPage>
  );
};

export { ClaimNFT };

// export const ClaimNFT = () => {
//   return (
//     <div>
//       ClaimNFT
//     </div>
//   );
// }