import React from "react";
import { Typography, Box } from "@mui/material";
import { NonRedirectPage } from "pages/NonLandingPage/NonRedirectPage";
import { RevealCode } from "./RevealCode";
import { CtaButton } from "components";
import { useNavigate } from "react-router-dom";

function DisplayClaimCode() {
  const navigate = useNavigate();
  const [hideMenu, setHideMenu] = React.useState(false);
  const [revealQRCode, setRevealQRCode] = React.useState(false);

  const clickRevealCode = () => {
    setHideMenu(true);
    setRevealQRCode(true);
  };

  return (
    <NonRedirectPage>
      {!hideMenu && (
        <Box mt={5}>
          <CtaButton onClick={() => clickRevealCode()}>
            <Typography variant="dg_button">Reveal QR Code</Typography>
          </CtaButton>
        </Box>
      )}
      {revealQRCode && (
        <RevealCode/>
      )}
    </NonRedirectPage>
  );
}

export { DisplayClaimCode };
