import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

export const SocialButton = styled(Button)({
  color: "#303030",
  borderRadius: "0px",
  border: "1px solid #E2E2E2;",
  background: "var(--primary-white, #FFF)",
  textTransform: "none",
  //maxWidth: "350px",
  height: "48px",
  fontWeight: "500px",
  flexShrink: "0",

  "&:hover": {
    borderRadius: "0px",
    border: "1px solid #E2E2E2;",
    background: "var(--primary-white, #FFF)",
  },
  "&:active": {
    borderRadius: "0px",
    border: "1px solid #E2E2E2;",
    background: "var(--primary-red, #FFF)",
  },  
  "&.Mui-disabled": {
    background: "#CC000059",
  },
});
