import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import translationEn from './assets/locales/en/translation.json';

// Language resources
const resources = {
  en: {
    translation: { ...translationEn },
  },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: false,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  
  react: {
    useSuspense: true,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'b', 'strong', 'i'],
  },
  
});

export default i18n;
