import React from "react";
import { useDGPasswordless } from "dgsdk";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SignoutLink = () => {
  const { t } = useTranslation();
  const { dgSignOut } = useDGPasswordless();
  const navigate = useNavigate();
  return (    
    <Button variant="link" 
      onClick={async () => {        
        await dgSignOut();
        navigate("/");
      }}>
        <Typography variant="dg_body1">
          {t("signout")}
        </Typography>
    </Button>
  );
};

export { SignoutLink };