import React, { useRef, useEffect, useState } from "react";

import Box from "@mui/material/Box";

import QRCodeStyling from "qr-code-styling";

// Has to be outside the component
const qrCode = new QRCodeStyling({
  // width: 344,
  // height: 344,
  dotsOptions: {
    color: "#5f1b5e",
    type: "rounded",
  },
  cornersSquareOptions: {
    color: "#5f1b5e",
    type: "extra-rounded",
  },
  cornersDotOptions: {
    color: "#5f1b5e",
    type: "dot",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10,
    hideBackgroundDots: true,
  },
});

interface ShowQRCodeProps {
  url: string;
  borderColor?: string;
  backgroundColor?: string;
}


// eslint-disable-next-line react/prop-types
const ShowQRCode:React.FC<ShowQRCodeProps> = ({url, borderColor = "#FFFFFF", backgroundColor = "#FFFFFF"}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [renderTime, setRenderTime] = useState<number>(Date.now());

  

  useEffect(() => {
    qrCode.append(ref.current as HTMLDivElement);
  }, []);

  useEffect(() => {
    console.log("Generating QR code: ", url);
    qrCode.update({
      data: url,
    });
  }, [url]);

  useEffect(() => {
    setRenderTime(Date.now());
    qrCode.update({
      backgroundOptions: {
        color: backgroundColor,
      },
    });
  }, [backgroundColor]);

  const boxStyle = {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    height: "100%",
    margin: "auto",
    paddingTop: "10px",
    background: backgroundColor,
    //border: `1.89873px solid ${borderColor}`,
    //boxShadow: "0px 0px 35.1562px rgba(112, 252, 143, 0.8)",
    //borderRadius: "16.4062px",
  };

  return (
    <Box sx={{ justifyContent: "center", alignItems: "center" }}>
      <Box sx={(renderTime > 0) ? boxStyle : boxStyle}>
        <div ref={ref} />
      </Box>
    </Box>
  );
}

export { ShowQRCode };
