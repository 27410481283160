import { useContext, forwardRef } from "react";

import { MainContext } from "../../contexts/MainContext";
import { Link } from "react-router-dom";

//import { log_event_analytics } from "../../functions/log_event_analytics";

type Props = { to: string; };
export type Ref = HTMLAnchorElement;

// eslint-disable-next-line react/prop-types, react/display-name
const TrackableExtLink = forwardRef<Ref, Props>(({ to, ...rest }, ref) => {
  const mainContext = useContext(MainContext);

  return (
    <Link
      to={to}
      ref={ref}
      {...rest}
      onClick={() =>
        mainContext?.log_event_analytics("external_link", {
          location: mainContext?.pathname,
          url: to,
        })
      }
    />
  );
});

export { TrackableExtLink };
