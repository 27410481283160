import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

export const CtaButton = styled(Button)({
  display: 'flex',
  //width: '343px',
  height: '48px',
  padding: '14px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  gap: '10px',
  flexShrink: '0',
  background: '#571A64',
  borderRadius: '0px',

  '&:hover': {
    backgroundColor: '#571A64',
    borderColor: '#571A64',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#571A64',
    borderColor: '#571A64',
    borderRadius: '0px',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
  "&.Mui-disabled": {
    backgroundColor: 'rgba(87, 26, 100, 0.30)',
  },
});
