import React, { Dispatch, SetStateAction } from "react";
import { Hub, Auth, Analytics, DataStore } from "aws-amplify";
import { useDGPasswordless, DGAuthState } from "../dgsdk";
import { makeSessionId, getLogger } from "../assets/utilities";


// Analytics and API Functions
import { addHours, callProxyWallet, callProxyAsset, callProxyClaim, callProxyRetrieveCode, callProxyEventInfo, callCreateWebClaim } from "../functions";

import { isMobile, browserName } from "react-device-detect";

// Google Analytics - GA4
import ReactGA from 'react-ga4';

// Cookie
import { useCookies } from 'react-cookie';

import { AppEnv, AppEntityId, AppAnalyticsKineisStreamName } from "../constants";

// Model
import { WebClaim } from "../models";

//type AsyncRetrieveFn = () => Promise<string>;

interface MainContextType {
  nonLandingToLoad: boolean;
  setNonLandingToLoad: Dispatch<SetStateAction<boolean>>;
  loaderInitLogic: () => Promise<void>;
  getRedirectUrl: () => string;
  getAsyncRedirectUrl: () => Promise<string>;
  signInRedirectUrl: string;
  sessionId: string;
  setSessionId: Dispatch<SetStateAction<string>>;
  updateSessionId: (sid: string) => void;
  userEmail: string;
  setUserEmail: Dispatch<SetStateAction<string>>;
  userSignInMethod: string;
  setUserSignInMethod: Dispatch<SetStateAction<string>>;
  loadingWallet: boolean;
  setLoadingWallet: Dispatch<SetStateAction<boolean>>;
  walletAddress: string;
  setWalletAddress: Dispatch<SetStateAction<string>>;
  isNewWallet: boolean;
  setIsNewWallet: Dispatch<SetStateAction<boolean>>;
  userAgent: string;
  setUserAgent: Dispatch<SetStateAction<string>>;
  pathname: string;
  setPathname: Dispatch<SetStateAction<string>>;
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
  referrerCode: string;
  setReferrerCode: Dispatch<SetStateAction<string>>;
  isMobile: boolean;
  setIsMobile: Dispatch<SetStateAction<boolean>>;
  browserName: string;
  setBrowserName: Dispatch<SetStateAction<string>>;
  userIpVersion: string;
  setUserIpVersion: Dispatch<SetStateAction<string>>;
  userIp: string;
  setUserIp: Dispatch<SetStateAction<string>>;
  userCountryCode: string;
  setUserCountryCode: Dispatch<SetStateAction<string>>;
  userCountryName: string;
  setUserCountryName: Dispatch<SetStateAction<string>>;
  userCity: string;
  setUserCity: Dispatch<SetStateAction<string>>;
  userTimezone: string;
  setUserTimezone: Dispatch<SetStateAction<string>>;
  userLatitude: string;
  setUserLatitude: Dispatch<SetStateAction<string>>;
  userLongitude: string;
  setUserLongitude: Dispatch<SetStateAction<string>>;
  userIpReady: boolean;
  setUserIpReady: Dispatch<SetStateAction<boolean>>;

  gaCampaignId: string;
  setGaCampaignId: Dispatch<SetStateAction<string>>;
  gaCampaign: string;
  setGaCampaign: Dispatch<SetStateAction<string>>;
  gaSource: string;
  setGaSource: Dispatch<SetStateAction<string>>;
  gaMedium: string;
  setGaMedium: Dispatch<SetStateAction<string>>;
  gaTerm: string;
  setGaTerm: Dispatch<SetStateAction<string>>;
  gaContent: string;
  setGaContent: Dispatch<SetStateAction<string>>;

  // HKMA Contract Address Lookup
  contractAddressLookup: any;
  queryWallet: () => Promise<{walletAddress: string, isNewWallet: boolean}>;
  queryWalletAsset: (wallet_address: string) => Promise<{nft: any}>;
  
  claimState: string;
  isWalletAssetLoaded: boolean;
  setIsWalletAssetLoaded: Dispatch<SetStateAction<boolean>>;
  walletAsset: any;
  setWalletAsset: Dispatch<SetStateAction<any>>;

  homeRouteTo: string;
  setHomeRouteTo: Dispatch<SetStateAction<string>>;
  resetMagicLinkInvalid: () => void;
  

  // For QRCode Claim Process
  isRedirectClaimPage: boolean;
  setIsRedirectClaimPage: Dispatch<SetStateAction<boolean>>;
  redirectClaimEventId: string;
  setRedirectClaimEventId: Dispatch<SetStateAction<string>>;
  updateRedirectClaimEventId: (eventId: string) => void;
  updateCompleteClaimEvent: () => void;
  claimScannedNFT: () => Promise<void>;
  queryEventInfo: (eventId: string) => Promise<any>;
  refreshWalletAssetAfterClaim: (contractAddresses: string[]) => Promise<boolean>;

  // Analytics function
  log_event_analytics: (event_name: string, attributes: any) => void;

  // Retrieve Claim Code
  retrieveClaimCode: () => Promise<string>;
};

export const MainContext = React.createContext<MainContextType | null>(null);

// eslint-disable-next-line react/prop-types
export const MainProvider = ({ children } : { children : React.ReactNode }) => {
  const logger = getLogger("context:Main");
  // From DGPasswordless
  const { getBearerToken, signedInEmail, signedInMethod, preSignOutAt, signOutAt, authState, resetMagicLinkFlow } = useDGPasswordless();

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies([
    'sid', 'auto_login', 
    'ga_utm_campaign_id', 'ga_utm_campaign', 'ga_utm_campaign_source', 
    'ga_utm_campaign_medium', 'ga_utm_campaign_term', 'ga_utm_campaign_content',
    'nft_claim_state', 
    'redirect_claim', 'claim_event_id',
  ]);
  const fromCookieOrNew = () => {
    if (cookies.sid !== undefined) {
      return cookies.sid;
    } else {
      const newSessionId = makeSessionId(10);
      // MaxAge set to 10 Hours
      setCookie('sid', newSessionId, { path: '/', maxAge: 60 * 60 * 10 });
      return newSessionId;
    }
  }
  const [loader, setLoader] = React.useState<boolean>(false);
  const [adminSuppress, setAdminSuppress] = React.useState<boolean>(false);
  const [nonLandingToLoad, setNonLandingToLoad] = React.useState(true);
  const [prevNonLandingToLoad, setPrevNonLandingToLoad] = React.useState(false);
  const [signInRedirectUrl, setSignInRedirectUrl] = React.useState("");
  const [sessionId, setSessionId] = React.useState(fromCookieOrNew());
  const [userEmail, setUserEmail] = React.useState("");
  const [userSignInMethod, setUserSignInMethod] = React.useState("");
  const [loadingWallet, setLoadingWallet] = React.useState(false);
  const [walletAddress, setWalletAddress] = React.useState("");
  const [isNewWallet, setIsNewWallet] = React.useState(false);
  const [userAgent, setUserAgent] = React.useState("");
  const [pathname, setPathname] = React.useState("");
  const [language, setLanguage] = React.useState("en");
  const [referrerCode, setReferrerCode] = React.useState("");
  const [isMobile, setIsMobile] = React.useState(false);
  const [browserName, setBrowserName] = React.useState("");
  const [userIpVersion, setUserIpVersion] = React.useState("");
  const [userIp, setUserIp] = React.useState("");
  const [userCountryCode, setUserCountryCode] = React.useState("");
  const [userCountryName, setUserCountryName] = React.useState("");
  const [userCity, setUserCity] = React.useState("");
  const [userTimezone, setUserTimezone] = React.useState("");
  const [userLatitude, setUserLatitude] = React.useState("");
  const [userLongitude, setUserLongitude] = React.useState("");
  const [userIpReady, setUserIpReady] = React.useState(false);

  const [gaCampaignId, setGaCampaignId] = React.useState((cookies?.ga_utm_campaign_id !== undefined ? cookies.ga_utm_campaign_id : ""));
  const [gaCampaign, setGaCampaign] = React.useState((cookies?.ga_utm_campaign !== undefined ? cookies.ga_utm_campaign : ""));
  const [gaSource, setGaSource] = React.useState((cookies?.ga_utm_campaign_source !== undefined ? cookies.ga_utm_campaign_source : ""));
  const [gaMedium, setGaMedium] = React.useState((cookies?.ga_utm_campaign_medium !== undefined ? cookies.ga_utm_campaign_medium : ""));
  const [gaTerm, setGaTerm] = React.useState((cookies?.ga_utm_campaign_term !== undefined ? cookies.ga_utm_campaign_term : ""));
  const [gaContent, setGaContent] = React.useState((cookies?.ga_utm_campaign_content !== undefined ? cookies.ga_utm_campaign_content : ""));

  const [isWalletAssetLoaded, setIsWalletAssetLoaded] = React.useState(false);
  const [walletAsset, setWalletAsset] = React.useState({ nft: [] as any});

  // For routing
  const [homeRouteTo, setHomeRouteTo] = React.useState("");

  // HKMA NFT Claim Flow
  const [claimState, setClaimState] = React.useState((cookies?.nft_claim_state !== undefined ? cookies.nft_claim_state : ""));
  const [isClaimCompleted, setIsClaimCompleted] = React.useState(false);
  const [contractAddressLookup, setContractAddressLookup] = React.useState({hkma: [] as string[]});

  // For QRCode Claim Process
  const [isRedirectClaimPage, setIsRedirectClaimPage] = React.useState(false);
  const [redirectClaimEventId, setRedirectClaimEventId] = React.useState("");

  // Internal state to control Proxy wallet loading
  // Flag to indicate whether to load wallet: default is true
  const [requireLoadWallet, setRequireLoadWallet] = React.useState(true);

  React.useEffect(() => {
    if (pathname.startsWith("/admin")) {
      setAdminSuppress(true);
    } else {
      setAdminSuppress(false);
    }
  }, [pathname]);

  // getRedirectUrl
  const getRedirectUrl = () => {
    let redirectUrl: string = window.location.origin + "/profile?entity=" + AppEntityId;
    // Language
    if (language !== "") {
      redirectUrl = redirectUrl + "&language=" + language;
    } else {
      redirectUrl = redirectUrl + "&language=en";
    }
    // Session
    if (sessionId !== "") {
      redirectUrl = redirectUrl + "&session=" + sessionId;
    }
    // Claim NFT
    if (redirectClaimEventId !== "") {
      
      redirectUrl = redirectUrl + "&claim_nft=" + encodeURIComponent(redirectClaimEventId);
    } else if (cookies.claim_event_id !== undefined) {
      redirectUrl = redirectUrl + "&claim_nft=" + encodeURIComponent(cookies.claim_event_id);
    }
    return redirectUrl;
  }

  const getAsyncRedirectUrl = () => new Promise<string>((resolve, reject) => {
    let redirectUrl: string = window.location.origin + "/verify?entity=" + AppEntityId;
    // Language
    if (language !== "") {
      redirectUrl = redirectUrl + "&language=" + language;
    } else {
      redirectUrl = redirectUrl + "&language=en";
    }
    // Session
    if (sessionId !== "") {
      redirectUrl = redirectUrl + "&session=" + sessionId;
    }
    // Auto Login
    redirectUrl = redirectUrl + "&auto_login=true";
    // Claim NFT
    if (redirectClaimEventId !== "") {
      redirectUrl = redirectUrl + "&claim_nft=" + encodeURIComponent(redirectClaimEventId);
    } else if (cookies.claim_event_id !== undefined) {
      redirectUrl = redirectUrl + "&claim_nft=" + encodeURIComponent(cookies.claim_event_id);
    }
    logger.debug(`getAsyncRedirectUrl: ${redirectUrl}`);
    resolve(redirectUrl);
  });

  React.useEffect(() => {
    const newUrl = getRedirectUrl();
    //console.log("[MainContext] getRedirectUrl (language): ", newUrl);
    setSignInRedirectUrl(newUrl);
  }, [language]);
  React.useEffect(() => {
    const newUrl = getRedirectUrl();
    //console.log("[MainContext] getRedirectUrl (sessionId): ", newUrl);
    setSignInRedirectUrl(newUrl);
  }, [sessionId]);
  React.useEffect(() => {
    const newUrl = getRedirectUrl();
    //console.log("[MainContext] getRedirectUrl (redirectClaimEventId): ", newUrl);
    setSignInRedirectUrl(newUrl);
  }, [redirectClaimEventId]);

  // Fetch Location
  const retrieveLocation = async () => {
    try {
      const response: Response = await fetch("https://d3498idtkol8eq.cloudfront.net/prod/track");
      const data = await response.json();
      if (data) {
        //console.log("data from track POST: ", data);
        setUserIpVersion(data.version);
        setUserIp(data.ip);
        setUserCountryCode(data.country_code_iso3);
        setUserCountryName(data.country_name);
        setUserCity(data.city);
        setUserTimezone(data.timezone);
        setUserLatitude(data.latitude);
        setUserLongitude(data.longitude);
        // Flag ready
        setUserIpReady(true);
      }
    } catch (err) {
      logger.warn(`Error in retrieveLocation: ${err}`);
    }
    setLoader(true);
  };

  // Update Session ID
  const updateSessionId = (overrideSid: string) => {
    if (overrideSid !== undefined) {
      // Log Analytics
      log_event_analytics("override_sid", { "old_session_id": sessionId, "new_session_id": overrideSid });

      // Override session id
      setSessionId(overrideSid);
      setCookie('sid', overrideSid, { path: '/', maxAge: 60 * 60 * 10 });
    }
  }

  React.useEffect(() => {
    if (userIpReady) {
      log_event_analytics("user_ip_located", { path: pathname });
    }
  }, [userIpReady]);

  // Analytics: log_event function
  const log_event_analytics = async (event_name: string, attributes: any) => {
    try {
      if (sessionId !== "") {
        let analytics_attributes = attributes;
        if ((event_name === "pageview") || (event_name === "returned_user") || (event_name.startsWith("signin") || (event_name === "user_ip_located"))) {
          if (gaCampaignId !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_campaign_id": gaCampaignId };
          }
          if (gaCampaign !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_campaign": gaCampaign };
          }
          if (gaSource !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_source": gaSource };
          }
          if (gaMedium !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_medium": gaMedium };
          }
          if (gaTerm !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_term": gaTerm };
          }
          if (gaContent !== "") {
            analytics_attributes = { ...analytics_attributes, "ga_content": gaContent };
          }
        }
        
        const env = AppEnv;
        // eslint-disable-next-line no-undef
        const corporate_event = process.env.REACT_APP_ANALYTICS_CORPORATE_EVENT || "default";
        const d_local = new Date();
        const d_utc = new Date(
          Date.UTC(
            d_local.getUTCFullYear(),
            d_local.getUTCMonth(),
            d_local.getUTCDate(),
            d_local.getUTCHours(),
            d_local.getUTCMinutes(),
            d_local.getUTCSeconds()
          )
        );
        const d_rpt = addHours(d_utc, 8);
        const log_data = {
          corporate_event: corporate_event,
          session_id: sessionId,
          user_email: userEmail,
          user_sign_in_method: userSignInMethod,        
          user_agent: userAgent,
          log_datetime: d_utc.toISOString(),
          local_datetime: d_rpt.toISOString(),
          is_mobile: isMobile,
          brower_name: browserName,
          user_ip_version: userIpVersion,
          user_ip: userIp,
          user_country_code: userCountryCode,
          user_country_name: userCountryName,
          user_city: userCity,
          user_timezone: userTimezone,
          user_latitude: userLatitude,
          user_longitude: userLongitude,
          language: language,
          event_name: event_name,
          attributes: JSON.stringify(analytics_attributes),
          year: d_rpt.getUTCFullYear().toString().padStart(2, "0"),
          month: (d_rpt.getUTCMonth() + 1).toString().padStart(2, "0"),
          day: d_rpt.getUTCDate().toString().padStart(2, "0"),
          hour: d_rpt.getUTCHours().toString().padStart(2, "0"),
          // year: d_rpt.getFullYear().toString().padStart(2, "0"),
          // month: (d_rpt.getMonth() + 1).toString().padStart(2, "0"),
          // day: d_rpt.getDate().toString().padStart(2, "0"),
          // hour: d_rpt.getHours().toString().padStart(2, "0"),
        };
        //(`log_event_analytics to ${AppAnalyticsKineisStreamName}-${env}:`, log_data);
        await Analytics.record(
          {
            data: log_data,
            partitionKey: "session_id",
            streamName: `${AppAnalyticsKineisStreamName}-${env}`,
          },
          "AWSKinesis"
        );
        //console.log(`sent log_event_analytics to ${AppAnalyticsKineisStreamName}-${env}`);

        //console.log("log_event_analytics: ", log_data);
      }    
    } catch (error) {
      logger.warn(`Error in log_event_analytics: ${error}`);
    }
  };

  // For QRCode Claim Process
  const updateRedirectClaimEventId = (eventId: string) => {
    setIsRedirectClaimPage(true);
    setRedirectClaimEventId(eventId);
    // const rebuildUrl = rebuildRedirectUrlWithClaimEventId(eventId);
    // console.log("[MainContext] updated redirect URL: ", rebuildUrl);
    // setSignInRedirectUrl(rebuildUrl);
    setCookie('redirect_claim', true, { path: '/', maxAge: 60 * 10});
    setCookie('claim_event_id', eventId, { path: '/', maxAge: 60 * 10 });
  };

  const updateCompleteClaimEvent = () => {
    setIsRedirectClaimPage(false);
    removeCookie('redirect_claim', { path: '/' });
    removeCookie('claim_event_id', { path: '/' });
    setRedirectClaimEventId("");
    // const rebuildUrl = rebuildRedirectUrlWithClaimEventId("");
    // console.log("[MainContext] updated redirect URL: ", rebuildUrl);
    // setSignInRedirectUrl(rebuildUrl);
  };

  const claimScannedNFT = () => new Promise<void>((resolve, reject) => {
    // Function to trigger HKMA NFT claim at backend
    logger.debug(`[claimScannedNFT] for wallet=${walletAddress} and event_id=${redirectClaimEventId}`);
    // Change State to Submitted
    try {
      if (redirectClaimEventId !== undefined && redirectClaimEventId !== "") {
        //setIsClaimCompleted(false);
        // Proxy Claim will handle the dynamic QR code (i.e. event_id scanned)
        getBearerToken().then((bearer) => {
          callProxyClaim({"event_id": redirectClaimEventId}, bearer, onClaimScannedCompleted, onClaimScannedError);
          setCookie('nft_claim_state', "submitted", { path: '/' });
          setClaimState("submitted");

          // Log event to Analytics
          log_event_analytics("claim_scanned_nft", { "wallet_address": walletAddress, "event_id": redirectClaimEventId });
          ReactGA.event("claim_scanned_nft", { wallet_address: walletAddress, "event_id": redirectClaimEventId});

          // Mark claim flow triggering as completed
          updateCompleteClaimEvent();

          // Resolve promise
          resolve();
        }).catch((err) => {
          reject(err);
        });
      }
    } catch (claim_err) {
      //console.log("Error in claimScannedNFT: ", claim_err);
      logger.error(`[claimScannedNFT] error: ${claim_err}`);
      log_event_analytics("error_claim_scanned_nft", { "wallet_address": walletAddress, "event_id": redirectClaimEventId, "error": `${claim_err}` });
      reject(claim_err);
    }
  });

  const onClaimScannedCompleted = () => {
    // Log event analytics
    log_event_analytics("claim_scanned_nft_completed", { "email": userEmail, "wallet_address": walletAddress });
    ReactGA.event("claim_scanned_nft_completed", { wallet_address: walletAddress});
    
    removeCookie('nft_claim_state', { path: '/' });
    setClaimState("");
    setIsClaimCompleted(true);

    // Save to WebClaim
    getBearerToken().then((bearer) => {
      callCreateWebClaim({
        id: makeSessionId(24),
        email: userEmail,
        contractAddress: contractAddressLookup.hkma[0],
        tokenId: "",
        claimDate: (new Date()).toISOString(),
      }, bearer).then((data: any) => {
        // Created record
      }).catch((createErr) => {
        //console.log("[MainContext] Error in saveToWebClaim while error: ", createErr);
        logger.error(`[claimCompleted] error in saveToWebClaim: ${createErr}`);
      });
    }).catch((err) => {
      //console.log("[MainContext] Error in saveToWebClaim while error in getBearerToken: ", err);
      logger.error(`[claimCompleted] error in saveToWebClaim: ${err}`);
    });
  };

  const onClaimScannedError = (errCode: number, errMsg: string) => {
    //console.log("[MainContext] Error in onClaimScannedError: ", errCode, errMsg);
    logger.error(`[claimError] error: ${errCode}-${errMsg}`);
    log_event_analytics("claim_scanned_nft_failed", { "email": userEmail, "wallet_address": walletAddress, "event_id": redirectClaimEventId, "error": `${errCode}: ${errMsg}` });

    removeCookie('nft_claim_state', { path: '/' });
    setClaimState("");
    setIsClaimCompleted(true);
  };

  const queryEventInfo = (eventId: string) => new Promise<any>((resolve, reject) => {
    getBearerToken().then((bearer) => {
      callProxyEventInfo({"event_id": eventId}, bearer).then((eventInfo: any) => {
        resolve(eventInfo);
      }).catch((err) => {
        reject(err);
      });
    }).catch((err) => {
      reject(err);
    });
  });

  const retrieveClaimCode = () => new Promise<string>((resolve, reject) => {
    getBearerToken().then((bearer) => {
      callProxyRetrieveCode(bearer).then((code: string) => {
        resolve(code);
      }).catch((err: any) => {
        //console.log("[retrieveClaimCode] error: ", err);
        logger.error(`[retrieveClaimCode] error: ${err}`);
        reject(err);
      });
    }).catch((err) => {
      reject(err);
    });
  });

  const queryWallet = () => new Promise<{walletAddress: string, isNewWallet: boolean}>((resolve, reject) => {
    setLoadingWallet(true);
    getBearerToken().then((bearer) => {
      callProxyWallet({}, bearer, (data: any) => {
        const json_data = JSON.parse(data);
        setWalletAddress(json_data.wallet_address);
        setIsNewWallet(json_data.is_new_wallet);
        setLoadingWallet(false);
        resolve({walletAddress: json_data.wallet_address, isNewWallet: json_data.is_new_wallet});
      }, (errCode: number, errMsg: string) => {
        reject(errMsg);
      });
    }).catch((err) => {
      reject(err);
    });
  });

  const queryWalletAsset = (wallet_address: string) => new Promise<{nft: any}>((resolve, reject) => {
    getBearerToken().then((bearer) => {
      callProxyAsset({"wallet_address": wallet_address}, bearer, (asset_data) => {
        setWalletAsset({ nft: JSON.parse(asset_data)});
        setIsWalletAssetLoaded(true);
        resolve({nft: JSON.parse(asset_data)});
      }, (assetErrCode, assetErrMsg) => {
        reject(assetErrMsg);
      });
    }).catch((err) => {
      reject(err);
    });
  });


  // Handlers for Hub
  const onProxyWalletSuccess = (data: any) => {
    //console.log("onProxyWalletSuccess: ", data);
    setLoadingWallet(false);
    const json_data = JSON.parse(data);
    //console.log("onProxyWalletSuccess parsed:", json_data);
    if (json_data.wallet_address !== undefined) {
      setWalletAddress(json_data.wallet_address);
      let isNewWallet = false;
      if (json_data.is_new_wallet) {
        setIsNewWallet(true);
        isNewWallet = true;

        // Log analytics for new wallet created
        log_event_analytics("created_new_wallet", { "email": json_data.email, "wallet_address": json_data.wallet_address });
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const onProxyWalletFailure = (errCode: number, errMsg: string) => {
    logger.warn(`[MainContext] Error in callProxyWallet: ${errCode}-${errMsg}`);
    log_event_analytics("error_proxy_wallet", { "email": userEmail, "errorCode": errCode, "error": errMsg });
    setLoadingWallet(false);
    setHomeRouteTo("/error");
  };

  const checkAndCallProxyWallet = () => {
    //console.log("[checkAndCallProxyWallet] Loading wallet for the current user");
    if (!adminSuppress) {
      getBearerToken().then((bearer) => {
        if (requireLoadWallet) {
          setLoadingWallet(true);
          setIsWalletAssetLoaded(false);
          callProxyWallet({}, bearer, onProxyWalletSuccess, onProxyWalletFailure);
          setRequireLoadWallet(false);
        } //else {
        //  console.log("No need to load wallet for the current user");
        //}
      }).catch((err) => {
        logger.warn("[checkAndCallProxyWallet] Error in callProxyWallet while error in getBearerToken: ", err);
      });
    }
  };

  const refreshWalletAssetAfterClaim = (contractAddresses: string[]) => new Promise<boolean>((resolve, reject) => {
    // Call Proxy Asset API
    getBearerToken().then((bearer) => {
      callProxyAsset({"wallet_address": walletAddress}, bearer, (asset_data) => {
      //callProxyAsset({"wallet_address": "0x03419c927649bfc1d9962637febf6c50ffa787e4"}, (asset_data) => {
        // Check for existence of interested contract address
        const nft_data = JSON.parse(asset_data);
        let isContractAddressFound = false;
        for (let i = 0; i < nft_data.length; i++) {
          if (contractAddresses.includes(nft_data[i].contract_address.toLowerCase())) {
            isContractAddressFound = true;
            break;
          }
        }
        setWalletAsset({ nft: nft_data});
        // Notify caller for any existence
        resolve(isContractAddressFound);
      }, (assetErrCode, assetErrMsg) => {
        log_event_analytics("error_proxy_asset", { "wallet_address": walletAddress, "errorCode": assetErrCode, "error": assetErrMsg });
        logger.warn(`[refreshWalletAssetAfterClaim] Error: ${assetErrCode}-${assetErrMsg}`);
        reject(assetErrMsg);
      });
    }).catch((err) => {
      logger.warn("[refreshWalletAssetAfterClaim] Error in getBearerToken: ", err);
      reject(err);
    });
  });

  React.useEffect(() => {
    setUserSignInMethod(signedInMethod);
  }, [signedInMethod]);

  // React.useEffect(() => {
  //   if ((nonLandingToLoad) && (prevNonLandingToLoad !== nonLandingToLoad)) {
  //     // Only if valid signedInEmail (or user is authenticated)
  //     if (signedInEmail !== "") {
  //       checkAndCallProxyWallet();
  //     }
  //   }
  //   setPrevNonLandingToLoad(nonLandingToLoad);
  // }, [nonLandingToLoad]);

  // useEffect to track DGPasswordless for an authenticated user
  React.useEffect(() => {
    //console.log(`[MainContext] signedInEmail changed: ${signedInEmail} with (nonLandingToLoad=${nonLandingToLoad})`);
    setUserEmail(signedInEmail);
    if (signedInEmail !== "") {
      logger.debug(`MainContext returned_user event (Email=${signedInEmail}, Method=${signedInMethod})`);
      // Log analytics for returned user
      log_event_analytics("returned_user", { "email": signedInEmail, "method": signedInMethod });

      // calling API for Proxy Wallet only after the user email is retrieved
      // if (nonLandingToLoad) {
      //   checkAndCallProxyWallet();
      // }
    }
  }, [signedInEmail]);

  const resetMagicLinkInvalid = () => {
    resetMagicLinkFlow();
    // Assume sign out
    setRequireLoadWallet(true);
    setUserSignInMethod("");
    setLoadingWallet(false);
    setWalletAddress("");
    setIsNewWallet(false);
    setIsWalletAssetLoaded(false);
    setWalletAsset({nft: []});
    setNonLandingToLoad(false);
    updateCompleteClaimEvent();
    removeCookie('auto_login', { path: '/' });
  };

  // Sign Out
  React.useEffect(() => {
    if (preSignOutAt > 0) {
      // Assume sign out to be successful
      setRequireLoadWallet(true);
      setUserSignInMethod("");
      setLoadingWallet(false);
      setWalletAddress("");
      setIsNewWallet(false);
      setIsWalletAssetLoaded(false);
      setWalletAsset({nft: []});
      setNonLandingToLoad(false);
      updateCompleteClaimEvent();
      removeCookie('auto_login', { path: '/' });
    }
  }, [preSignOutAt]);

  React.useEffect(() => {
    if (signOutAt > 0) {
      // Assume sign out
      setRequireLoadWallet(true);
      setUserSignInMethod("");
      setLoadingWallet(false);
      setWalletAddress("");
      setIsNewWallet(false);
      setIsWalletAssetLoaded(false);
      setWalletAsset({nft: []});
      setNonLandingToLoad(false);
      updateCompleteClaimEvent();
      removeCookie('auto_login', { path: '/' });

      // Set Home Route
      window.location.href = "/";
      //setHomeRouteTo("/");
    }
  }, [signOutAt]);

  // Need to log analytics for new user sign in action
  // signin-google
  // signin-email


  React.useEffect(() => {
    // Read Contract Address Lookup at initial page load
    // eslint-disable-next-line no-undef
    const dataContractAddress = process.env.REACT_APP_HKMA_CONTRACT_ADDRESSES || "";
    const dataContractAddressArray = dataContractAddress.split(",");
    setContractAddressLookup({hkma: dataContractAddressArray});

    // Browser Tracking
    setUserAgent(window.navigator.userAgent);
    setIsMobile(isMobile);
    setBrowserName(browserName);

    // Retrieve Location
    retrieveLocation();
  }, []);

  const loaderInitLogic = () => new Promise<void>((resolve, reject) => {
    // Scanned QR Code for event claim
    // Only load cookies if redirectClaimEventId is empty
    logger.debug(`[loaderInitLogic] loader triggered with redirectClaimEventId: ${redirectClaimEventId}`);
    if (redirectClaimEventId === "") {
      const c_redirect_claim = cookies?.redirect_claim;
      const c_claim_event_id = cookies?.claim_event_id;
      if (c_redirect_claim !== undefined && c_redirect_claim === true) {
        setIsRedirectClaimPage(true);
      }
      if (c_claim_event_id !== undefined && c_claim_event_id !== "") {
        logger.debug("[loaderInitLogic] Retrieve from Cookie: ", c_claim_event_id, " to use as event id");
        setRedirectClaimEventId(c_claim_event_id);
        if (c_redirect_claim === false) {
          setIsRedirectClaimPage(true);
        }
      }
    } else {
      logger.debug("[loaderInitLogic] Skipping claim event id cookies retrieval");
    }

    // Check whether user is authenticated
    logger.debug(`[loaderInitLogic] loader triggered, authenticated state: ${authState}`);
    if ((authState === DGAuthState.SignedInPasswordless) || (authState === DGAuthState.SignedInSocialGoogle)) {
      // Check any auto sign in in cookie
      const c_auto_login = cookies?.auto_login || false;
      // Remove cookies after usage
      removeCookie('auto_login', { path: '/' });
      logger.debug(`[loaderInitLogic] auto login: ${c_auto_login} (event_id: ${cookies?.claim_event_id})`);
      if ((cookies?.claim_event_id !== undefined) && (cookies?.claim_event_id !== "")) {
        setHomeRouteTo("/profile-claim");
      } else if ((c_auto_login === true) || (c_auto_login === "true")) {
        // Auto Routing
        logger.debug("[loaderInitLogic] auto login detected, redirect to profile / claim page");
        setHomeRouteTo("/profile");
      } else {
        // Keep at Home page
      }

      resolve();
    } else if (authState === DGAuthState.UnauthenticatedMagicLinkError) {
      setHomeRouteTo("/failed");
    } else if ((authState === DGAuthState.Unknown) || (authState === DGAuthState.Unauthenticated)) {
      // Check Auth from Amplify to see any existing session
      Auth.currentAuthenticatedUser().then((user) => {
        // Check any auto sign in in cookie
        const c_auto_login = cookies?.auto_login || false;
        // Remove cookies after usage
        removeCookie('auto_login', { path: '/' });
        logger.debug(`[loaderInitLogic] auto login: ${c_auto_login}, (event_id: ${cookies?.claim_event_id})`);
        if ((cookies?.claim_event_id !== undefined) && (cookies?.claim_event_id !== "")) {
          setHomeRouteTo("/profile-claim");
        } else if ((c_auto_login === true) || (c_auto_login === "true")) {
          // Auto Routing
          logger.debug("[loaderInitLogic] auto login detected, redirect to profile / claim page");
          setHomeRouteTo("/profile");
        } else {
          // Keep at Home page
        }

      }).catch((err) => {
        // No active sesion => unauthenticated
      });
    }
  });

  React.useEffect(() => {
    if (loader) {
      loaderInitLogic().then(() => {
        logger.debug(`[loaderInitLogic] loaderInitLogic completed, current path: ${pathname}`);
      });
    }
  }, [loader]);

  return (
    <MainContext.Provider
      value={{
        nonLandingToLoad, 
        setNonLandingToLoad,
        loaderInitLogic,
        getRedirectUrl,
        getAsyncRedirectUrl,
        signInRedirectUrl,
        sessionId,
        setSessionId,
        updateSessionId,
        userEmail,
        setUserEmail,
        userSignInMethod,
        setUserSignInMethod,
        loadingWallet,
        setLoadingWallet,
        walletAddress,
        setWalletAddress,
        isNewWallet,
        setIsNewWallet,
        userAgent,
        setUserAgent,
        pathname,
        setPathname,
        language,
        setLanguage,
        referrerCode,
        setReferrerCode,
        isMobile,
        setIsMobile,
        browserName,
        setBrowserName,
        userIpVersion,
        setUserIpVersion,
        userIp,
        setUserIp,
        userCountryCode,
        setUserCountryCode,
        userCountryName,
        setUserCountryName,
        userCity,
        setUserCity,
        userTimezone,
        setUserTimezone,
        userLatitude,
        setUserLatitude,
        userLongitude,
        setUserLongitude,
        userIpReady,
        setUserIpReady,

        gaCampaignId,
        setGaCampaignId,
        gaCampaign,
        setGaCampaign,
        gaSource,
        setGaSource,
        gaMedium,
        setGaMedium,
        gaTerm,
        setGaTerm,
        gaContent,
        setGaContent,

        queryWallet,
        queryWalletAsset,

        isWalletAssetLoaded,
        setIsWalletAssetLoaded,
        walletAsset,
        setWalletAsset,

        homeRouteTo,
        setHomeRouteTo,
        resetMagicLinkInvalid,

        // HKMA NFT Claim Flow
        contractAddressLookup, 
        claimState,

        // For QRCode Claim Process
        isRedirectClaimPage,
        setIsRedirectClaimPage,
        redirectClaimEventId,
        setRedirectClaimEventId,
        updateRedirectClaimEventId,
        updateCompleteClaimEvent,
        claimScannedNFT,
        queryEventInfo,
        refreshWalletAssetAfterClaim,

        log_event_analytics,
        retrieveClaimCode,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
