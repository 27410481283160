import React from 'react';
import { useLocation, Navigate, Routes, Route, useSearchParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Home, SignIn, Verify, VerifyFailed, Error, ErrorInvalidCode, ErrorExpiredCode,
  Profile, ClaimNFT, Welcome, DisplayClaimCode, RevealCode, Test } from './pages';
import { ProtectedRoutes } from './dgsdk';
import { MainContext } from 'contexts/MainContext';
import ReactGA from 'react-ga4';
import { getLogger } from 'assets/utilities';

const TrackableRouterApp = () => {
  const logger = getLogger("service:TrackableRouterApp");
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['auto_login', 'ga_utm_campaign_id', 'ga_utm_campaign', 'ga_utm_campaign_source', 'ga_utm_campaign_medium', 'ga_utm_campaign_term', 'ga_utm_campaign_content']);
  const mainContext = React.useContext(MainContext);

  React.useEffect(() => {
    logger.debug(`[App] pathname: ${pathname}, searchParams: ${searchParams}`);
    if (searchParams.has("session")) {
      mainContext?.updateSessionId(searchParams.get("session") || "");
    }
    if (searchParams.has("auto_login")) {
      setCookie('auto_login', searchParams.get("auto_login") || "false", { path: '/', maxAge: 60 * 10 });
    }
    if (searchParams.has("utm_id")) {
      mainContext?.setGaCampaignId(searchParams.get("utm_id") || "");
      setCookie('ga_utm_campaign_id', searchParams.get("utm_id"), { path: '/' });
    }
    if (searchParams.has("utm_campaign")) {
      mainContext?.setGaCampaign(searchParams.get("utm_campaign") || "");
      setCookie('ga_utm_campaign', searchParams.get("utm_campaign"), { path: '/' });
    }
    if (searchParams.has("utm_source")) {
      mainContext?.setGaSource(searchParams.get("utm_source") || "");
      setCookie('ga_utm_campaign_source', searchParams.get("utm_source"), { path: '/' });
    }
    if (searchParams.has("utm_medium")) {
      mainContext?.setGaMedium(searchParams.get("utm_medium") || "");
      setCookie('ga_utm_campaign_medium', searchParams.get("utm_medium"), { path: '/' });
    }
    if (searchParams.has("utm_term")) {
      mainContext?.setGaTerm(searchParams.get("utm_term") || "");
      setCookie('ga_utm_campaign_term', searchParams.get("utm_term"), { path: '/' });
    }
    if (searchParams.has("utm_content")) {
      mainContext?.setGaContent(searchParams.get("utm_content") || "");
      setCookie('ga_utm_campaign_content', searchParams.get("utm_content"), { path: '/' });
    }
    
    if (searchParams.has("claim_nft")) {
      const decodedParam = decodeURIComponent(searchParams.get("claim_nft") || "");
      logger.debug(`[App] claim_nft: ${decodedParam}`);
      mainContext?.updateRedirectClaimEventId(decodedParam);
      // Log Analytics
      mainContext?.log_event_analytics("scan_qrcode", { event_id: decodedParam });
      ReactGA.event("scan_qrcode", { event_id: decodedParam});
      
    }
    mainContext?.setPathname(pathname);
    mainContext?.log_event_analytics("pageview", { path: pathname });
  }, [pathname]);

  React.useEffect(() => {
    if (mainContext?.homeRouteTo) {
      if (mainContext?.homeRouteTo !== "") {
        logger.debug(`[App] homeRouteTo: ${mainContext?.homeRouteTo}`);
        navigate(mainContext?.homeRouteTo, { replace: true });
      }
    }
  }, [mainContext?.homeRouteTo]);

  return (
    <Routes>
      <Route path='/' key="home" element={<Home />} />
      <Route path='/signin' key="signin" element={<SignIn />} />
      <Route path='/verify' key="verify" element={<Verify />} />
      <Route path='/failed' key="failed" element={<VerifyFailed />} />
      <Route path='/error' key="error" element={<Error />} />
      <Route path='/error-invalid' key="error-invalid" element={<ErrorInvalidCode />} />
      <Route path='/error-expired' key="error-expired" element={<ErrorExpiredCode />} />
      <Route path='/welcome' key="welcome" element={<Welcome />} />
      {/* <Route path='/test' key="test" element={<Test />} /> */}
      <Route element={<ProtectedRoutes unauthenticatedRoute='/' />}>
        <Route path='/profile' key="profile" element={<Profile />} />
        <Route path='/profile-claim' key="profile-claim" element={<ClaimNFT />} />
        <Route path='/admin-functions' key="admin-functions" element={<DisplayClaimCode />} />
        <Route path='/admin-reveal-claim-code' key="admin-reveal-code" element={<RevealCode />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default TrackableRouterApp;