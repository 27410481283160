import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CssTextField = styled(TextField)({  
  'height': '48px',
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(43, 43, 43, 0.15)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(43, 43, 43, 0.15)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(43, 43, 43, 0.15)',
    },
  },
});

export { CssTextField }
