/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const proxywallet = /* GraphQL */ `
  mutation Proxywallet($input: AWSJSON) {
    proxywallet(input: $input)
  }
`;
export const proxyasset = /* GraphQL */ `
  mutation Proxyasset($input: AWSJSON) {
    proxyasset(input: $input)
  }
`;
export const proxyclaim = /* GraphQL */ `
  mutation Proxyclaim($input: AWSJSON) {
    proxyclaim(input: $input)
  }
`;
export const createWebClaim = /* GraphQL */ `
  mutation CreateWebClaim(
    $input: CreateWebClaimInput!
    $condition: ModelWebClaimConditionInput
  ) {
    createWebClaim(input: $input, condition: $condition) {
      id
      email
      contractAddress
      tokenId
      claimDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWebClaim = /* GraphQL */ `
  mutation UpdateWebClaim(
    $input: UpdateWebClaimInput!
    $condition: ModelWebClaimConditionInput
  ) {
    updateWebClaim(input: $input, condition: $condition) {
      id
      email
      contractAddress
      tokenId
      claimDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWebClaim = /* GraphQL */ `
  mutation DeleteWebClaim(
    $input: DeleteWebClaimInput!
    $condition: ModelWebClaimConditionInput
  ) {
    deleteWebClaim(input: $input, condition: $condition) {
      id
      email
      contractAddress
      tokenId
      claimDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDynamicQRCode = /* GraphQL */ `
  mutation CreateDynamicQRCode(
    $input: CreateDynamicQRCodeInput!
    $condition: ModelDynamicQRCodeConditionInput
  ) {
    createDynamicQRCode(input: $input, condition: $condition) {
      pk
      dramatic_event_id
      qrcode
      updated_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDynamicQRCode = /* GraphQL */ `
  mutation UpdateDynamicQRCode(
    $input: UpdateDynamicQRCodeInput!
    $condition: ModelDynamicQRCodeConditionInput
  ) {
    updateDynamicQRCode(input: $input, condition: $condition) {
      pk
      dramatic_event_id
      qrcode
      updated_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDynamicQRCode = /* GraphQL */ `
  mutation DeleteDynamicQRCode(
    $input: DeleteDynamicQRCodeInput!
    $condition: ModelDynamicQRCodeConditionInput
  ) {
    deleteDynamicQRCode(input: $input, condition: $condition) {
      pk
      dramatic_event_id
      qrcode
      updated_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
