/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const proxyeventinfo = /* GraphQL */ `
  query Proxyeventinfo($input: AWSJSON) {
    proxyeventinfo(input: $input)
  }
`;
export const proxyretrievecode = /* GraphQL */ `
  query Proxyretrievecode($input: AWSJSON) {
    proxyretrievecode(input: $input)
  }
`;
export const getWebClaim = /* GraphQL */ `
  query GetWebClaim($id: ID!) {
    getWebClaim(id: $id) {
      id
      email
      contractAddress
      tokenId
      claimDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWebClaims = /* GraphQL */ `
  query ListWebClaims(
    $filter: ModelWebClaimFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWebClaims(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        contractAddress
        tokenId
        claimDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDynamicQRCode = /* GraphQL */ `
  query GetDynamicQRCode($pk: String!) {
    getDynamicQRCode(pk: $pk) {
      pk
      dramatic_event_id
      qrcode
      updated_at
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDynamicQRCodes = /* GraphQL */ `
  query ListDynamicQRCodes(
    $pk: String
    $filter: ModelDynamicQRCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDynamicQRCodes(
      pk: $pk
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        pk
        dramatic_event_id
        qrcode
        updated_at
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
