import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { proxyeventinfo } from "../graphql/queries";
import { ProxyeventinfoQuery } from "../API";

const callProxyEventInfo = async (data: any, bearerToken: string) => {
  const apiRequest = await API.graphql({
    query: proxyeventinfo,
    variables: { input: JSON.stringify(data) },
    authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
    authToken: bearerToken,
  });
  const apiResponse = apiRequest as { data: ProxyeventinfoQuery; errors: any[] };
  //console.log("[callRetrieveCode] apiRequest: ", apiRequest);
  const response = JSON.parse(apiResponse.data.proxyeventinfo || "{}");
    
  /*
  Response body documentation
  
  */
  if (response.statusCode === 200) {
    const response_body = response.body;
    if (response_body) {
      return response_body;
    } else {
      throw new Error("No response body");
    }
  } else if (response.statusCode === 410) {
    throw new Error("Code expired");
  } else {
    throw new Error(`[callProxyEventInfo] Error in claim NFT: ${response.statusCode}: ${response.body}`);
  }
};

export { callProxyEventInfo };
