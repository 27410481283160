import React from "react";
import { Container, Box, Grid } from "@mui/material";
//import { Auth } from "aws-amplify";
//import { Link } from "@aws-amplify/ui-react";
import logoHkmaWhite from "../../assets/images/logo_hkma30_white.png";
import logoHkmaColor from "../../assets/images/logo_hkma30_color.png";
import logoDottedWhite from "../../assets/images/logo_dotted_white.png";
import logoDottedColor from "../../assets/images/logo_dotted_color.png";

export function Navbar({ isLandingPage }: { isLandingPage: boolean}) {  
  return (
    <Container sx={{ position: "sticky", top: 0, zIndex: 10, background: (isLandingPage ? "transparent" : "white") }}>
      <Box py={1}>
        <Grid container alignItems={"center"}>
          <Grid item container xs={6} justifyContent={"flex-start"}>
            {isLandingPage ? (
              <img src={logoHkmaWhite} width={172} alt="HKMA 30th" />
            ) : (
              <img src={logoHkmaColor} width={172} alt="HKMA 30th" />
            )}
          </Grid>
          <Grid item container xs={6} justifyContent={"flex-end"}>
            {isLandingPage ? (
              <img src={logoDottedWhite} width={94} alt="DOTTED" />
            ) : (
              <img src={logoDottedColor} width={94} alt="DOTTED" />            
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

