import React from "react";
import { useSearchParams } from "react-router-dom";
import { useDGPasswordless, DGAuthState } from "dgsdk";
import { Backdrop, CircularProgress } from "@mui/material";
import { NonLandingPage } from "pages/NonLandingPage/main";
import { MainContext } from "contexts/MainContext";

function Verify() {
  const { authState } = useDGPasswordless();  
  const mainContext = React.useContext(MainContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openLoading, setOpenLoading] = React.useState(true);
  
  React.useEffect(() => {
    setOpenLoading(true);    
  }, [searchParams, setSearchParams]);

  React.useEffect(() => {
    if (authState === DGAuthState.SignedInPasswordless) {
      setOpenLoading(false);
      mainContext?.loaderInitLogic();
    } else if (authState === DGAuthState.UnauthenticatedMagicLinkError) {
      mainContext?.setHomeRouteTo("/failed");
    }
  }, [authState]);

  const progressWhiteStyle = {
    "&.MuiCircularProgress-colorPrimary": {
      color: "rgba(255, 255, 255, 0.88) !important",
    }
  };

  return (
    <NonLandingPage>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress sx={progressWhiteStyle} />
      </Backdrop>
    </NonLandingPage>
  );
}

export { Verify };
