const typography_landing_h1 = {
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
};

const typography_dg_h1 = {
  color: "#571A64",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
};

const typography_dg_h3 = {
  color: "var(--primary-darkgrey, #2B2B2B)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
};

const typography_body1 = {
  color: "#303030",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",
};

const typography_body2 = {
  color: "#555;",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
};

const typography_button = {  
  color: "#FFF",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "normal",
};

const typography_link = {  
  color: "var(--primary-grey, #676767)",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "100%",
  textDecorationLine: "underline",
};

const typography_footer = {
  color: "#fff",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Roboto",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "normal",
}

export { typography_landing_h1, typography_dg_h1, typography_dg_h3, typography_body1, typography_body2, typography_button, typography_link, typography_footer }
