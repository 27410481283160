import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { proxyasset } from "../graphql/mutations";
import { ProxyassetMutation } from "../API";


export const callProxyAsset = async (data: any, bearerToken: string, success: (data: any) => any, err: (code: number, msg: string) => any) => {
  try {
    const apiRequest = await API.graphql({
      query: proxyasset,
      variables: { input: JSON.stringify(data) },
      authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
      authToken: bearerToken,
    });
    const apiResponse = apiRequest as { data: ProxyassetMutation; errors: any[] };
    //console.log("[callProxyAsset] apiRequest: ", apiRequest);
    const response = JSON.parse(apiResponse.data.proxyasset || "{}");
    
    /*
    Response body documentation
    */
    if (response.statusCode === 200) {
      const response_body = response.body;
      if (response_body) {
        success(response_body);
      } else {
        err(400, response_body);
      }
    } else {
      err(response.statusCode, `[callProxyAsset] Error in register: ${response.statusCode}: ${response.body}`);
    }
  } catch (error) {
    //console.log("[callProxyAsset] Error in API call: ", error);
    if (err) {
      err(500, `${error}`);
    }
  }
};
