export const STREAM_NAME = "dghkmafintech2023Kinesis";

export function addHours(date: Date, hours: number) {
  // 👇 Make copy with "Date" constructor.
  const dateCopy = new Date(date);
  dateCopy.setHours(dateCopy.getHours() + hours);
  return dateCopy;
}

export {
  callProxyAsset,
} from './callProxyAsset';
export {
  callProxyWallet,
} from './callProxyWallet';
export {
  callProxyClaim,
} from './callProxyClaim';
export {
  callProxyRetrieveCode,
} from './callProxyRetrieveCode';
export {
  callProxyEventInfo,
} from './callProxyEventInfo';
export {
  callCreateWebClaim,
} from './callCreateWebClaim';