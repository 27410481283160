import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { proxyretrievecode } from "../graphql/queries";
import { ProxyretrievecodeQuery } from "../API";

export const callProxyRetrieveCode = async (bearerToken: string) => {
  const apiRequest = await API.graphql({
    query: proxyretrievecode,
    variables: { input: JSON.stringify({}) },
    authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
    authToken: bearerToken,
  });
  const apiResponse = apiRequest as { data: ProxyretrievecodeQuery; errors: any[] };
  //console.log("[callRetrieveCode] apiRequest: ", apiRequest);
  const response = JSON.parse(apiResponse.data.proxyretrievecode || "{}");
  
  /*
  Response body documentation
  
  */
  if (response.statusCode === 200) {
    const response_body = response.body;
    if (response_body) {
      const response_body_json = JSON.parse(response_body);
      const { code } = response_body_json;
      return code;
    } else {
      throw new Error("No response body");
    }
  } else {
    throw new Error(`[callRetrieveCode] Error: ${response.statusCode}`);
  }
  
};

