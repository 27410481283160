import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { createWebClaim } from "../graphql/mutations";
import { CreateWebClaimInput, CreateWebClaimMutation } from "../API";

export const callCreateWebClaim = async (data: any, bearerToken: string) => {
  
  const requestDetails: CreateWebClaimInput = {
    ...data,
  };

  const apiRequest = await API.graphql({
    query: createWebClaim,
    variables: { input: requestDetails },
    authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
    authToken: bearerToken,
  });
  const apiResponse = apiRequest as { data: CreateWebClaimMutation; errors: any[] };
  
  return apiResponse;
  
};
