import React, { ReactNode } from "react";
import { Box, Stack, Grid, Backdrop, CircularProgress } from "@mui/material";
import { AppMinHeight } from "../../constants";
import { Navbar, Footer } from "../../components";
import { MainContext } from "contexts/MainContext";
import { useNavigate } from "react-router-dom";

// implementation
export interface NonLandingPageProps extends React.ComponentPropsWithoutRef<typeof Box> {
  children: ReactNode;
}

export function NonLandingPage(prop: NonLandingPageProps) {
  const mainContext = React.useContext(MainContext);
  const navigate = useNavigate();
  const [openLoading, setOpenLoading] = React.useState(false);

  React.useEffect(() => {
    mainContext?.setNonLandingToLoad(true);
  }, []);

  React.useEffect(() => {
    if (mainContext?.homeRouteTo) {
      navigate(mainContext.homeRouteTo);
    }
  }, [mainContext?.homeRouteTo]);

  React.useEffect(() => {
    if (mainContext?.loadingWallet) {
      setOpenLoading(true);
    } else {
      setOpenLoading(false);      
    }
  }, [mainContext?.loadingWallet]);

  const containerWhiteStyle = {
    maxWidth: "430px",
    background: "white",
    minHeight: AppMinHeight
  };

  const backgroundWhiteStyle = {
    minHeight: AppMinHeight,
    background: "white",
  };

  return (
    <Stack direction="column">
      <Box sx={backgroundWhiteStyle}>
        <Box sx={containerWhiteStyle }>
          <Grid container justifyContent="center">
            <Grid item xs={12} container justifyContent="center">
              <Navbar isLandingPage={false} />
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <Box>
                {prop.children}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Stack>
  );
}
