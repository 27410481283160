/* eslint-disable camelcase */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { CookiesProvider } from 'react-cookie';

import { PasswordlessAuthContextProvider } from './dgsdk';
import { MainProvider } from './contexts/MainContext';

import './i18n';

// Amplify related
import awsExports from './aws-exports';
import { Amplify, Analytics, AWSKinesisProvider } from 'aws-amplify';

import ReactGA from 'react-ga4';

// Init GA Analytics
ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID || "");

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

// Override Auth for Amplify
const AwsConfigAuth = {
  Auth: {
    region: process.env.REACT_APP_PASSWORDLESS_COGNITO_IDP_REGION || 'ap-southeast-1',
    userPoolId: process.env.REACT_APP_PASSWORDLESS_COGNITO_USER_POOL || '',
    userPoolWebClientId: process.env.REACT_APP_PASSWORDLESS_COGNITO_CLIENT_ID || '',
    oauth: {
      domain: process.env.REACT_APP_PASSWORDLESS_COGNITO_DOMAIN || 'auth.passwordless.dttd.wtf',
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN || '',
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT || '',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  oauth: {
    domain: process.env.REACT_APP_PASSWORDLESS_COGNITO_DOMAIN || 'auth.passwordless.dttd.wtf',
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN || '',
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT || '',
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  aws_cognito_identity_pool_id: process.env.REACT_APP_PASSWORDLESS_COGNITO_IDENTITY_POOL || '',
};

// Override API for Amplify
const AwsConfigAPI = {
  API: {
    endpoints: [
      {
        name: "passwordless-signin",
        endpoint: process.env.REACT_APP_PASSWORDLESS_SIGNIN_API_ENDPOINT || '',
      }
    ]
  }
};

const combinedAwsConfig = { ...awsExports, ...AwsConfigAuth, ...AwsConfigAPI };
//Amplify.Logger.LOG_LEVEL = 'DEBUG';
//Amplify.configure(awsExports);
//console.log("PasswordlessAuthContextProvider: -- Overriding AwsConfigAuth --");
Amplify.configure(combinedAwsConfig);

Analytics.addPluggable(new AWSKinesisProvider());

Analytics.configure({
  AWSKinesis: {
    // OPTIONAL -  Amazon Kinesis service region
    region: awsExports.aws_project_region,
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <PasswordlessAuthContextProvider authenticatedRoute="/profile" unauthenticatedRoute="/">
      <MainProvider>
        <App />
      </MainProvider>
    </PasswordlessAuthContextProvider>
  </CookiesProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
