import React from 'react';
import {
  usePasswordless,
} from './passwordless/react';

const PasswordlessController = ({ children }: { children: React.ReactNode}) => {
  // const { signInStatus, tokens, tokensParsed } = usePasswordless();



  // React.useEffect(() => {
  //   console.log('PasswordlessController: useEffect at signInStatus change');
  //   console.log('PasswordlessController: signInStatus:', signInStatus);
  // }, [signInStatus]);

  // React.useEffect(() => {
  //   console.log('PasswordlessController: useEffect at tokens change');
  //   console.log('PasswordlessController: tokens:', tokens);
  // }, [tokens]);

  // React.useEffect(() => {
  //   console.log('PasswordlessController: useEffect at tokensParsed change');
  //   console.log('PasswordlessController: tokensParsed:', tokensParsed);
  // }, [tokensParsed]);

  return (
    <>
      {children}
    </>
  );
};

export default PasswordlessController;