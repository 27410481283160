import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { NonRedirectPage } from "pages/NonLandingPage/NonRedirectPage";
import { Trans, useTranslation } from "react-i18next";
import ImgNFT from "assets/images/nft_hkma30_display.png";
import { SocialButton } from "components";
import { useDGPasswordless } from "dgsdk";

function Error() {
  const navigate = useNavigate();
  const { isAuthenticated, dgSignOut } = useDGPasswordless();
  const { t } = useTranslation();
  
  const progressRedStyle = {
    "&.MuiCircularProgress-colorPrimary": {
      color: "rgba(255, 0, 0, 0.84) !important",
    }
  };


  return (
    <>
      <NonRedirectPage>
        <Grid container>
          <Grid item xs={12} mt={1} container justifyContent="center">
            <img src={ImgNFT} width={253} alt="HKMA 30th" />
          </Grid>
          <Grid item xs={12} mt={1.5} container justifyContent="center">
            <Typography variant="dg_h1">
              Something wrong happened!
            </Typography>
          </Grid>
          <Grid item xs={12} mt={1.5} container justifyContent="center">
            <Typography variant="dg_body1">
              <Trans i18nKey={"error.expired_code.subtitle"} components={{ b: <strong />, br: <br />}} />
            </Typography>
          </Grid>
          <Grid item xs={12} mt={3} container justifyContent="center">
            <SocialButton fullWidth onClick={() => navigate("/")}>
              <Typography variant="dg_body1" sx={{ textDecoration: 'underline'}}>
                {t("error.cta_button")}
              </Typography>
            </SocialButton>
          </Grid>
          {isAuthenticated && (
          <Grid item xs={12} mt={3} mb={3} container justifyContent="center">
            <SocialButton fullWidth sx={{ border: "transparent"}} onClick={() => dgSignOut()}>
              <Typography variant="dg_body1" sx={{ textDecoration: 'underline'}}>
                {t("signout")}
              </Typography>
            </SocialButton>
          </Grid>
          )}
        </Grid>
      </NonRedirectPage>
      
    </>
  );
}

export { Error };
